.cabtm_container {
    display: flex;
    justify-content: center;
    height: auto;
    align-items: center;
}

.faq_container {
    background-color: var(--darkblue);
    transform: translateY(-106px);
    padding-top: 90px;
    padding-bottom: 0px;
}

.faq_container .faq_container_title {
    text-align: center; 
    color: var(--white); 
    margin-bottom: 30px; 
    font-size: 40px; 
    line-height: 4; 
    letter-spacing: 0px;
}

#earnwithcabtain {
    min-height: 405vh;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .faq_container {
        background-color: var(--darkblue);
        transform: translateY(-106px);
        padding-top: 30px;
        padding-bottom: 0px;
    }
}

@media (max-width: 600px) {
    .faq_container .faq_container_title {
        text-align: center; 
        color: var(--white); 
        margin-bottom: 32px; 
        font-size: 20px!important; 
        line-height: 1!important; 
        letter-spacing: 1px;
    }

    .cabtm_container {
        display: flex;
        justify-content: center;
        height: auto;
        align-items: center;
        padding: 15% 0;
    }
}