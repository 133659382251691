.body {
    transform: translateY(-30.7rem);
}

#homescreen {
    height: 110vw;
}

/* .footer {
    transform: translateY(-27rem);
} */

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1)  {
    .body {
        transform: translateY(-26.7rem);
    }

    #homescreen {
        height: 110vw;
    }
}

@media (max-width: 600px) {
    .body {
        transform: translateY(-19.7rem);
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .body {
        transform: translateY(-19.7rem);
    }

}