.watch_video {
    background-image: url('./watch_video_img.png');
    background-size: 100%;
    height: 37rem;
    transform: translateY(-10px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.watch_video .watch_video_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.watch_video .watch_video_text p {
    color: #069F03;
    font-size: 70px;
    line-height: 50px;
    letter-spacing: 2px;
}

.watch_video .watch_video_text button {
    padding: 17px 41px;
    margin-top: 20px;
    width: auto;
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-right: 20px;
}

.watch_video .watch_video_btn {
    display: flex;
    justify-content: center;
}

.watch_video .watch_video_btn button:last-child {
    background: var(--white)!important;
}

.watch_video .watch_video_btn button:last-child:hover {
    color: var(--yellow)!important;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1)  {
    .watch_video {
        background-image: url('./watch_video_img.png');
        background-size: 100%;
        height: 37rem;
    }
    
    .watch_video .watch_video_text p {
        color: #069F03;
        font-size: 4rem;
        letter-spacing: 0px;
    }
    
    .watch_video .watch_video_text button {
        padding: 2%;
        margin-top: 15px;
        width: 14.5rem;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        margin-right: 20px;
    }

    .watch_video .watch_video_btn button:last-child {
        width: 12.5rem;
    }
}

@media (max-width: 600px) {
    .watch_video {
        background-image: url('./watch_video_img.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 16rem;
    }
    
    .watch_video .watch_video_text p {
        color: #069F03;
        font-size: 1.6rem;
        letter-spacing: 0px;
        margin-bottom: 3px;
    }
    
    .watch_video .watch_video_text button {
        padding: 3%;
        margin-top: 5px;
        width: 60%;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        margin-bottom: 10px!important;
        width: 70%;
    }

    .watch_video .watch_video_btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .watch_video .watch_video_btn button:last-child {
        background: var(--white)!important;
        width: 70%;
    }

    .watch_video .watch_video_btn button {
        margin-left: 0!important;
        margin-right: 0!important;
        
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .watch_video {
        background-image: url('./watch_video_img.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 22rem;
    }

    .watch_video .watch_video_text {
        /* position: absolute; */
        padding-top: 15.5%;
        padding-left: 10.6%;
    }
    
    .watch_video .watch_video_text p {
        color: #069F03;
        font-size: 2.2em;
        letter-spacing: 1px;
        margin-bottom: 14px;
    }
    
    .watch_video .watch_video_text button {
        padding: 3% 2%;
        margin-top: 8px;
        width: 15.5rem;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 2px;
    }
}