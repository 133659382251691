.faq {
    display: flex;
    justify-content: center;
    align-items: center;
}

.faq .faq_content {
    width: 65.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faq .faq_content .faq_btn_container {
    display: flex;
    justify-content: space-between;
    width: 43%;
    margin-bottom: 44px;
}

.faq .faq_content .faq_btn_container .faq_btn {
    box-shadow: 0px 1px 0px var(--lightblue);
    border-radius: 8px;
    padding: 2.9% 0;
    width: 261px;
    font-size: 0.86rem;
    border: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    height: 60px;
}

/* .faq .faq_content .cabtain_tab {
    display: none;
} */
.faq .faq_content .faq_btn_container .active {
    background-color: #C29200;
    color: var(--white);
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .faq {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .faq .faq_content {
        width: 65.5%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .faq .faq_content .faq_btn_container {
        display: flex;
        justify-content: space-between;
        width: 43%;
        margin-bottom: 44px;
    }
    
    .faq .faq_content .faq_btn_container .faq_btn {
        box-shadow: 0px 1px 0px var(--lightblue);
        border-radius: 8px;
        padding: 2% 0;
        width: 180px;
        font-size: 0.86rem;
        border: none;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        height: 42px;
        letter-spacing: 0.5px;
    }
    
    /* .faq .faq_content .cabtain_tab {
        display: none;
    } */
    .faq .faq_content .faq_btn_container .active {
        background-color: #C29200;
        color: var(--white);
    }
}

@media (max-width: 600px) {
    .faq {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .faq .faq_content {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .faq .faq_content .faq_btn_container {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin-bottom: 44px;
    }
    
    .faq .faq_content .faq_btn_container .faq_btn {
        box-shadow: 0px 1px 0px var(--lightblue);
        border-radius: 8px;
        padding: 2% 0;
        width: 48%;
        border: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        height: 38px;
        letter-spacing: 0.5px;
    }
    
    /* .faq .faq_content .cabtain_tab {
        display: none;
    } */
    .faq .faq_content .faq_btn_container .active {
        background-color: #C29200;
        color: var(--white);
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .faq {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .faq .faq_content {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .faq .faq_content .faq_btn_container {
        display: flex;
        justify-content: space-between;
        width: 65%;
        margin-bottom: 44px;
    }
    
    .faq .faq_content .faq_btn_container .faq_btn {
        box-shadow: 0px 1px 0px var(--lightblue);
        border-radius: 8px;
        padding: 2% 0;
        width: 180px;
        font-size: 0.86rem;
        border: none;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        height: 42px;
        letter-spacing: 0.5px;
    }
    
    /* .faq .faq_content .cabtain_tab {
        display: none;
    } */
    .faq .faq_content .faq_btn_container .active {
        background-color: #C29200;
        color: var(--white);
    }
}