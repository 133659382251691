@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

:root {
  --darkblue: #1B1C58;
  --lightblue: #2F3160;
  --yellow: #C29200;
  --grey: #484A5E;
  --lightgrey: #797A83;
  --white: #E2E2E2;
}


* {
    margin: 0;
    padding: 0;
    font-family: 'Ubuntu', sans-serif;
}

.App {
    background-color: #040726;
    /* max-height: 600vh; */
}

#body {
    background-color: #040726;
}

.cabtm_container {
    display: flex;
    justify-content: center;
    box-shadow: 0px -2px 0px var(--lightblue);
    border-radius: 100px 100px 0px 0px;
    background-color: var(--darkblue);
}

.cabtm_btn {
    background: linear-gradient(to right, var(--yellow) 100%, var(--yellow) 100%) no-repeat;
    box-shadow: 0px 0.5px 0px #2F3160;
    border-radius: 50px;
    border: none;
    color: var(--white);
    /* transition: background 1s ease-in-out; */
    font-weight: bold;
    /* background-position: top; */
    text-transform: uppercase;
}

.cabtm_btn:hover {
    background: linear-gradient(to right, #8d6a00 100%, var(--yellow) 0%) no-repeat;
    color: var(--lightblue);
    /* background-color: #55430e; */
}

.footer {
    transform: translateY(0rem);
}

.cabtm_body {
    transform: translateY(-9.2rem);
}

.cabtm_container .cabtm_container_content {
    display: flex; 
    width: 63%; 
    justify-content: space-between; 
    align-items: center;
    padding: 5% 0;
}

.pattern {
    background-image: url('./components/pattern.svg');
    background-repeat: repeat;
    /* background: 0.5%; */
}

.parallax {
    transition: transform 50ms ease-in-out;
}

.dashboard_container .quill {
    height: 55vh;
}



#mybtn {
    /* display: none; */
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: var(--white);
    color: var(--yellow);
    cursor: pointer;
    padding: 12px;
    border-radius: 100%;
    width: 52px;
    box-shadow: 0px 2px 6px #040726;
  }

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1)  {
    .cabtm_body {
        transform: translateY(-5.5rem);
    }

    #mybtn {
        /* display: none; */
        position: fixed;
        bottom: 50px;
        right: 30px;
        z-index: 99;
        font-size: 18px;
        border: none;
        outline: none;
        background-color: var(--white);
        color: var(--yellow);
        cursor: pointer;
        padding: 10px;
        border-radius: 100%;
        width: 48px;
    }
}

@media (max-width: 600px) {
    .cabtm_container {
        display: flex;
        justify-content: center;
        box-shadow: 0px -2px 0px var(--lightblue);
        border-radius: 30px 30px 0px 0px;
        background-color: var(--darkblue);
    }
    
    .cabtm_btn {
        box-shadow: 0px 1px 0px #2F3160;
        border-radius: 4px!important;
        border: none;
        color: var(--white);
    }

    .cabtm_body {
        transform: translateY(-1.2rem);
    }

    .cabtm_container .cabtm_container_content {
        display: flex; 
        width: 85%; 
        flex-direction: column;
        justify-content: space-between; 
        /* align-items: center; */
        padding: 5% 0;
    }

    .App {
        flex-direction: column;
    }

    #mybtn {
        /* display: none; */
        position: fixed;
        bottom: 50px;
        right: 30px;
        z-index: 99;
        font-size: 18px;
        border: none;
        outline: none;
        background-color: var(--white);
        color: var(--yellow);
        cursor: pointer;
        padding: 9px;
        border-radius: 100%;
        width: 44px;
      }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1)  {
    .cabtm_body {
        transform: translateY(-1.2rem);
    }
    
    .cabtm_btn {
        background-color: var(--darkblue);
        box-shadow: 0px 2px 0px #2F3160;
        border: none;
        color: var(--white);
    }

    .cabtm_container .cabtm_container_content {
        display: flex; 
        width: 80%; 
        flex-direction: column;
        justify-content: space-between; 
        /* align-items: center; */
        padding: 5% 0;
    }
}

#lottie{
    background-color:#fff;
    width:100%;
    height:100%;
    display:block;
    overflow: hidden;
    transform: translate3d(0,0,0);
    text-align: center;
    opacity: 1;
}