.navbar_custom {
    background-color: var(--darkblue);
    /* height: 50%!important; */
    padding: 1.1% 4.5%;
    box-shadow: 0px 2px 1px var(--lightblue);
    z-index: 1;
    position: sticky;
    top: 0;
    width: 100%;
}

.navbar_custom img {
    width: 241px;
    height: 47.92px;
}

.navbar_custom .navbar-toggler {
    color: var(--white)!important;
}

.navbar_custom .navbar-toggler .navbar-toggler-icon {
    color: var(--white)!important;
}

.navbar_custom .nav-link {
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 100;
    color: #FFFFFF!important;
    padding-right: 0!important; 
    padding-left: 0!important;
    line-height: 12px;
}

.navbar_custom .nav-link:hover::after {
    width: 100%;
}

.navbar_custom .nav-link:hover {
    font-weight: 300!important;
    color: var(--yellow)!important;
}

.navbar_custom .active {
    font-weight: 300!important;
    color: var(--yellow)!important;
    border-bottom: 2px solid var(--yellow);
}

.navbar_custom .nav-link::after{
    content: '';
    display: block;
    /* margin-top: 10px; */
    transform: translateY(10px);
    height: 2px;
    width: 0px;
    background: var(--yellow);
    transition: all 0.35s linear;
}

.navbar_custom .dropdown i {
    font-size: 0.8rem;
    margin-left: 5px;
}

.navbar_custom .dropdown .nav-link {
    display: flex;
    justify-content: space-between;
}

.navbar_custom .dropdown-menu {
    color: #FFFFFF;
    background-color: var(--darkblue);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.5rem;
    min-width: 5rem;
    text-align: center;
    box-shadow: 0px 1px 1px var(--lightblue);
}

.navbar_custom .dropdown-menu .dropdown-item {
    color: #FFFFFF;
    font-weight: 300;
}

.navbar_custom .dropdown-menu .dropdown-item:focus, 
.navbar_custom .dropdown-menu .dropdown-item:hover {
    font-weight: 300!important;
    color: var(--yellow)!important;
    background: none;
}

.navbar_custom .dropdown-menu .active, .navbar_custom .dropdown-menu .dropdown-item:active {
    color: none;
    text-decoration: none;
    background: none!important;
    border-bottom: 0px solid var(--yellow);
}

.navbar_custom .navbar-nav {
    width: 64.5%;
    justify-content: space-between;
}

.fas {
    font-size: 1.7rem;
}

.navbar-toggler {
    width: 60px;
    border: none;
    outline: none;
    height: 40px;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

@media (max-width: 600px){
    .navbar_custom {
        padding: 2% 2%;
    }

    .navbar-nav {
        margin-left: 0!important;
        padding: 4%;
        width: 90.5%!important;
    }

    .navbar_custom .nav-link {
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 18px;
        font-weight: 100;
    }

    .navbar_custom img {
        width: 130px;
        height: 27.92px;
    }

    .navbar_custom .active {
        border-bottom: 0px;
        font-weight: 300!important;
    }

    .navbar_custom .nav-link::after {
        display: none;
    }

    .fas {
        font-size: 1.4rem;
        font-weight: 700;
    }

    .navbar_custom .navbar-toggler {
        width: 40px;
    }

    .navbar_custom .dropdown-menu {
        min-width: 2rem;
        text-align: left;
    }
    
    .navbar_custom .dropdown .nav-link {
        display: flex;
        justify-content: normal;
        align-items: center;
    }

    .navbar_custom .dropdown i {
        font-size: 0.6rem;
        margin-left: 10px;
    }
}


@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .navbar_custom {
        padding: 1.1% 4.5%;
    }

    .navbar_custom .nav-link {
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 10px;
        font-weight: 100;
    }

    .navbar_custom img {
        width: 170px;
        height: 38.92px;
    }

    .navbar_custom .navbar-nav {
        width: 63.5%!important;
        justify-content: space-between;
    }

    .navbar_custom .dropdown i {
        font-size: 0.5rem;
        margin-left: 5px;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1){
    .navbar_custom {
        padding: 2% 4.5%;
    }

    .navbar-nav {
        margin-left: 0!important;
        padding: 3%;

    }

    .navbar_custom .nav-link {
        font-size: 20px;
        letter-spacing: 3px;
        line-height: 30px;
        font-weight: 100;
    }

    .navbar_custom img {
        width: 228px;
        height: 62.92px;
    }

    .navbar_custom .active {
        border-bottom: 0px;
        font-weight: 300!important;
    }

    .navbar_custom .nav-link::after {
        display: none;
    }

    .fas {
        font-size: 1.4rem;
    }
}