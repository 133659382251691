.privacy_policy_info p {
    color: var(--yellow);
    width: 997px;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px;
}

.privacy_policy_container {
    margin: 3% 0;
    width: 85%;
}

.privacy_policy_container .privacy_policy_heading {
    color: var(--white);
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    width: 46%;
    letter-spacing: 2px;
}

.privacy_policy_container .privacy_policy_para {
    color: #E2E2E2;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 2px;
}

.cabtm_privacy_content {
    width: 63%;
    display: flex;
    flex-direction: column;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .privacy_policy_info p {
        color: var(--yellow);
        width: 745px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0px;
    }
    
    .privacy_policy_container {
        margin: 3% 0;
        width: 85%;
    }
    
    .privacy_policy_container .privacy_policy_heading {
        color: var(--white);
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        width: 50%;
        letter-spacing: 0px;
    }
    
    .privacy_policy_container .privacy_policy_para {
        color: #E2E2E2;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 1px;
    }
}

@media (max-width: 600px) {
    .privacy_policy_info p {
        color: var(--yellow);
        width: 100%;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0px;
    }
    
    .privacy_policy_container {
        margin: 3% 0;
        width: 85%;
    }
    
    .privacy_policy_container .privacy_policy_heading {
        color: var(--white);
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        letter-spacing: 0px;
    }
    
    .privacy_policy_container .privacy_policy_para {
        color: #E2E2E2;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
        width: 100%;
    }

    .cabtm_privacy_content {
        width: 80%;
        flex-direction: column;
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .privacy_policy_info p {
        color: var(--yellow);
        width: 100%;
        font-size: 26px;
        line-height: 36px;
        letter-spacing: 0px;
    }
    
    .privacy_policy_container {
        margin: 3% 0;
        width: 90%;
    }
    
    .privacy_policy_container .privacy_policy_heading {
        color: var(--white);
        font-weight: 500;
        font-size: 26px;
        line-height: 36px;
        width: 100%;
        letter-spacing: 2px;
    }
    
    .privacy_policy_container .privacy_policy_para {
        color: #E2E2E2;
        font-size: 21px;
        line-height: 26px;
        letter-spacing: 1.8px;
        width: 100%;
    }

    .cabtm_privacy_content {
        width: 80%;
        flex-direction: column;
    }
}