@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
:root {
  --darkblue: #1B1C58;
  --lightblue: #2F3160;
  --yellow: #C29200;
  --grey: #484A5E;
  --lightgrey: #797A83;
  --white: #E2E2E2;
}


* {
    margin: 0;
    padding: 0;
    font-family: 'Ubuntu', sans-serif;
}

.App {
    background-color: #040726;
    /* max-height: 600vh; */
}

#body {
    background-color: #040726;
}

.cabtm_container {
    display: flex;
    justify-content: center;
    box-shadow: 0px -2px 0px #2F3160;
    box-shadow: 0px -2px 0px var(--lightblue);
    border-radius: 100px 100px 0px 0px;
    background-color: #1B1C58;
    background-color: var(--darkblue);
}

.cabtm_btn {
    background: linear-gradient(to right, #C29200 100%, #C29200 100%) no-repeat;
    background: linear-gradient(to right, var(--yellow) 100%, var(--yellow) 100%) no-repeat;
    box-shadow: 0px 0.5px 0px #2F3160;
    border-radius: 50px;
    border: none;
    color: #E2E2E2;
    color: var(--white);
    /* transition: background 1s ease-in-out; */
    font-weight: bold;
    /* background-position: top; */
    text-transform: uppercase;
}

.cabtm_btn:hover {
    background: linear-gradient(to right, #8d6a00 100%, #C29200 0%) no-repeat;
    background: linear-gradient(to right, #8d6a00 100%, var(--yellow) 0%) no-repeat;
    color: #2F3160;
    color: var(--lightblue);
    /* background-color: #55430e; */
}

.footer {
    -webkit-transform: translateY(0rem);
            transform: translateY(0rem);
}

.cabtm_body {
    -webkit-transform: translateY(-9.2rem);
            transform: translateY(-9.2rem);
}

.cabtm_container .cabtm_container_content {
    display: flex; 
    width: 63%; 
    justify-content: space-between; 
    align-items: center;
    padding: 5% 0;
}

.pattern {
    background-image: url(../../static/media/pattern.850ba460.svg);
    background-repeat: repeat;
    /* background: 0.5%; */
}

.parallax {
    transition: -webkit-transform 50ms ease-in-out;
    transition: transform 50ms ease-in-out;
    transition: transform 50ms ease-in-out, -webkit-transform 50ms ease-in-out;
}

.dashboard_container .quill {
    height: 55vh;
}



#mybtn {
    /* display: none; */
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: #E2E2E2;
    background-color: var(--white);
    color: #C29200;
    color: var(--yellow);
    cursor: pointer;
    padding: 12px;
    border-radius: 100%;
    width: 52px;
    box-shadow: 0px 2px 6px #040726;
  }

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1)  {
    .cabtm_body {
        -webkit-transform: translateY(-5.5rem);
                transform: translateY(-5.5rem);
    }

    #mybtn {
        /* display: none; */
        position: fixed;
        bottom: 50px;
        right: 30px;
        z-index: 99;
        font-size: 18px;
        border: none;
        outline: none;
        background-color: #E2E2E2;
        background-color: var(--white);
        color: #C29200;
        color: var(--yellow);
        cursor: pointer;
        padding: 10px;
        border-radius: 100%;
        width: 48px;
    }
}

@media (max-width: 600px) {
    .cabtm_container {
        display: flex;
        justify-content: center;
        box-shadow: 0px -2px 0px #2F3160;
        box-shadow: 0px -2px 0px var(--lightblue);
        border-radius: 30px 30px 0px 0px;
        background-color: #1B1C58;
        background-color: var(--darkblue);
    }
    
    .cabtm_btn {
        box-shadow: 0px 1px 0px #2F3160;
        border-radius: 4px!important;
        border: none;
        color: #E2E2E2;
        color: var(--white);
    }

    .cabtm_body {
        -webkit-transform: translateY(-1.2rem);
                transform: translateY(-1.2rem);
    }

    .cabtm_container .cabtm_container_content {
        display: flex; 
        width: 85%; 
        flex-direction: column;
        justify-content: space-between; 
        /* align-items: center; */
        padding: 5% 0;
    }

    .App {
        flex-direction: column;
    }

    #mybtn {
        /* display: none; */
        position: fixed;
        bottom: 50px;
        right: 30px;
        z-index: 99;
        font-size: 18px;
        border: none;
        outline: none;
        background-color: #E2E2E2;
        background-color: var(--white);
        color: #C29200;
        color: var(--yellow);
        cursor: pointer;
        padding: 9px;
        border-radius: 100%;
        width: 44px;
      }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1)  {
    .cabtm_body {
        -webkit-transform: translateY(-1.2rem);
                transform: translateY(-1.2rem);
    }
    
    .cabtm_btn {
        background-color: #1B1C58;
        background-color: var(--darkblue);
        box-shadow: 0px 2px 0px #2F3160;
        border: none;
        color: #E2E2E2;
        color: var(--white);
    }

    .cabtm_container .cabtm_container_content {
        display: flex; 
        width: 80%; 
        flex-direction: column;
        justify-content: space-between; 
        /* align-items: center; */
        padding: 5% 0;
    }
}

#lottie{
    background-color:#fff;
    width:100%;
    height:100%;
    display:block;
    overflow: hidden;
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
    text-align: center;
    opacity: 1;
}
/* box-shadow:
    inset 0 0 50px ,      
    inset 20px 0 80px #ffffff20,  
    inset -20px 0 80px #ffffff20,  
    inset 20px 0 300px #ffffff20, 
    inset -20px 0 300px #ffffff20,
    0 0 50px #ffffff30,     
    -10px 0 80px #ffffff30,        
    10px 0 80px #ffffff30;  */

.slide-right {
    -webkit-animation: slide 1s linear infinite;
            animation: slide 1s linear infinite;
}

@-webkit-keyframes glow {
    0% {
        opacity: 1;
    }
    60% {
        opacity: 0.5;
    }
    70% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes glow {
    0% {
        opacity: 1;
    }
    60% {
        opacity: 0.5;
    }
    70% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes btn_water {
    0% {
        background: linear-gradient(to right, var(--white) 10%, var(--yellow) 0%) no-repeat
    }
    60% {
        background: linear-gradient(to right, var(--white) 30%, var(--yellow) 0%) no-repeat
    }
    70% {
        background: linear-gradient(to right, var(--white) 50%, var(--yellow) 0%) no-repeat
    }
    100% {
        background: linear-gradient(to right, var(--white) 100%, var(--yellow) 0%) no-repeat
    }
}

@keyframes btn_water {
    0% {
        background: linear-gradient(to right, var(--white) 10%, var(--yellow) 0%) no-repeat
    }
    60% {
        background: linear-gradient(to right, var(--white) 30%, var(--yellow) 0%) no-repeat
    }
    70% {
        background: linear-gradient(to right, var(--white) 50%, var(--yellow) 0%) no-repeat
    }
    100% {
        background: linear-gradient(to right, var(--white) 100%, var(--yellow) 0%) no-repeat
    }
}

@-webkit-keyframes slide {
    0% {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
    25% {
        -webkit-transform: translateX(50%);
                transform: translateX(50%);
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    50% {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        opacity: 0;
    }
    70%{
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
        opacity: 100%;
    }
}

@keyframes slide {
    0% {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
    25% {
        -webkit-transform: translateX(50%);
                transform: translateX(50%);
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    50% {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        opacity: 0;
    }
    70%{
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
        opacity: 100%;
    }
}


@-webkit-keyframes navToggle {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@keyframes navToggle {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.about {
    background-image: url(../../static/media/pattern.850ba460.svg);
    padding: 5.8% 0;
    height: 988px!important;
}

.about_text {
    width: 62.5%;
}

.about_heading {
    font-size: 30px;
    letter-spacing: 2px;
    color: var(--yellow);
    font-weight: 300;
}


.about_main {
    font-size: 40px;
    letter-spacing: 2px;
    color: var(--white);
    width: 80%;
    line-height: 50px;
    font-weight: 100;
}

.about_sub {
    color: var(--lightgrey);
    width: 82%;
    font-size: 1.5rem;
    font-weight: 100;
}

.about_items_container {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}

.about_items_container .about_item  {
    width: 50%;
}

.about_items_container .about_item  .about_item_heading {
    color: #069F03;
    text-align: left;
    font-weight: 100;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: 2px;
}

.about_items_container .about_item  .about_item_para {
    color: var(--lightgrey);
    width: 90%;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    /* line-height: 36px; */
    /* letter-spacing: 2px; */
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .about {
        background-image: url(../../static/media/pattern.850ba460.svg);
        padding: 5.8% 0;
        height: 830px!important;
    }
    
    .about_text {
        width: 62.5%;
    }
    
    .about_heading {
        font-size: 1.35rem;
        color: var(--yellow);
        font-weight: 300;
        letter-spacing: 1px;
    }
    
    
    .about_main {
        font-size: 1.76rem;
        color: var(--white);
        line-height: 36px;
        /* width: 95%; */
        letter-spacing: 1px;
    }
    
    .about_sub {
        color: var(--lightgrey);
        width: 82%;
        font-size: 1.05rem;
        letter-spacing: 1px;
    }
    
    .about_items_container {
        display: flex;
        justify-content: space-between;
    }
    
    .about_items_container .about_item  {
        width: 50%;
    }
    
    .about_items_container .about_item  .about_item_heading {

        color: #069F03;
        text-align: left;
        font-size: 1.4rem;
        letter-spacing: 1px;
    }
    
    .about_items_container .about_item  .about_item_para {
        color: var(--lightgrey);
        width: 90%;
        font-size: 1.05rem;
        letter-spacing: 1px;
    }
}

@media (max-width: 600px) {
    .about {
        background-image: url(../../static/media/pattern.850ba460.svg);
        padding: 10% 0;
        height: auto!important;
    }
    
    .about_text {
        /* margin: 30% 0; */
        width: 85%;
    }
    
    .about_heading {
        font-size: 1rem;
        color: var(--yellow);
        font-weight: 300;
        letter-spacing: 1px;
    }
    
    
    .about_main {
        font-size: 1.5rem;
        color: var(--white);
        line-height: 32px;
        /* width: 95%; */
        letter-spacing: 1px;
        width: 100%;
    }
    
    .about_sub {
        color: var(--lightgrey);
        /* width: 90%; */
        font-size: 1rem;
        line-height: 24px;
        letter-spacing: 0.5px;
        width: 100%;
    }
    
    .about_items_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 10px;
    }
    
    .about_items_container .about_item  {
        width: 100%;
    }
    
    .about_items_container .about_item  .about_item_heading {

        color: #069F03;
        text-align: left;
        font-size: 1.3rem;
        letter-spacing: 1px;
        margin-bottom: 8px;
        width: 100%;
    }
    
    .about_items_container .about_item  .about_item_para {
        color: var(--lightgrey);
        /* width: 90%; */
        font-size: 1rem;
        line-height: 24px;
        letter-spacing: 0.5px;
        width: 100%;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .about {
        background-image: url(../../static/media/pattern.850ba460.svg);
        padding: 20% 0;
        height: 1100px!important;
    }
    
    .about_text {
        /* margin: 30% 0; */
        width: 80%;
    }
    
    .about_heading {
        font-size: 1.4rem;
        color: var(--yellow);
        font-weight: 100;
        letter-spacing: 1px;
    }
    
    
    .about_main {
        font-size: 1.5rem;
        color: var(--white);
        line-height: 30px;
        /* width: 95%; */
        letter-spacing: 1px;
        width: 100%;
    }
    
    .about_sub {
        color: var(--lightgrey);
        /* width: 82%; */
        font-size: 1.1rem;
        letter-spacing: 1px;
        margin-right: 1px;
        width: 100%;
        line-height: 28px;
    }
    
    .about_items_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 10px;
    }
    
    .about_items_container .about_item  {
        width: 100%;
    }
    
    .about_items_container .about_item  .about_item_heading {

        color: #069F03;
        text-align: left;
        font-size: 1.4rem;
        letter-spacing: 1px;
        margin-bottom: 8px;
        width: 100%;
    }
    
    .about_items_container .about_item  .about_item_para {
        color: var(--lightgrey);
        /* width: 90%; */
        font-size: 1.1rem;
        line-height: 28px;
        letter-spacing: 0.5px;
        width: 100%;
    }
}
.download_app {
    height: 887px;
    -webkit-transform: translateY(-60px);
            transform: translateY(-60px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
}

.download_app_content {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.download_app_content .download_app_photo img {
    width: 483px;
    height: 663px;
}

.download_app_content .download_app_text .download_app_text_heading {
    font-size: 2.5rem;
    color: var(--white);
    margin-bottom: 10px;
    letter-spacing: 2px;
    font-weight: 100;
}

.download_app_content .download_app_text .download_app_text_sub {
    color: var(--lightgrey);
    font-weight: 400;
    font-size: 1.5rem;
    font-weight: 100;
    letter-spacing: 1px;
}

.download_app_text .footer_downloads {
    display: flex;
    flex-direction: column;
    /* padding: 1% 0%; */
    margin-top: 28px;
}



.download_app_text .footer_downloads .footer_downloads_item {
   display: flex;
   align-items: flex-start;
   flex-direction: column;
   width: auto;
   margin-bottom: 34px;
   
}

.download_app_text .footer_downloads .footer_downloads_item .downloads_title {
   color: var(--yellow);
   font-size: 24px;
   line-height: 28px;
   margin-bottom: 12px;
   letter-spacing: 2px;
   font-weight: 100;
}

.download_app_text .footer_downloads .footer_downloads_item .downloads_links .google_play {
   margin-right: 4px;
   width: 206px;
   height: 60px;
}

.download_app_text .footer_downloads .footer_downloads_item .downloads_links .app_store {
   margin-right: 4px;
   width: 178px;
   height: 64px;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .download_app {
        height: 631px;
        -webkit-transform: translateY(-60px);
                transform: translateY(-60px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .download_app_content {
        width: 60%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .download_app_content .download_app_photo img {
        height: 30rem;
        width: 21rem;
    }
    
    .download_app_content .download_app_text .download_app_text_heading {
        font-size: 1.8rem;
        color: var(--white);
        margin-bottom: 10px;
        letter-spacing: 1px;
    }
    
    .download_app_content .download_app_text .download_app_text_sub {
        color: var(--lightgrey);
        font-weight: 400;
        font-size: 1.1rem;
        letter-spacing: 0.5px;
    }
    
    .download_app_text .footer_downloads {
        display: flex;
        flex-direction: column;
        /* padding: 1% 0%; */
        margin-top: 28px;
    }
    
    
    
    .download_app_text .footer_downloads .footer_downloads_item {
       display: flex;
       align-items: flex-start;
       flex-direction: column;
       width: auto;
       margin-bottom: 15px;
       
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_title {
       color: var(--yellow);
       font-size: 1.15rem;
       margin-bottom: 5px;
       letter-spacing: 0.5px;
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_links .google_play {
       margin-right: 4px;
       width: 105.17px;
       height: 30.79px;
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_links .app_store {
       margin-right: 4px;
       width: 110.17px;
       height: 40.79px;
    }
}

@media (max-width: 600px) {
    .download_app {
        height: auto;
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20% 0;
    }
    
    .download_app_content {
        width: 85%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
    }
    
    .download_app_content .download_app_photo img {
        height: 18rem;
        width: 13.5rem;
        margin-bottom: 40px;
    }
    
    .download_app_content .download_app_text .download_app_text_heading {
        font-size: 1.4rem;
        color: var(--white);
        margin-bottom: 10px;
        letter-spacing: 1px;
    }
    
    .download_app_content .download_app_text .download_app_text_sub {
        color: var(--lightgrey);
        font-weight: 400;
        font-size: 1rem;
        letter-spacing: 1px;
    }
    
    .download_app_text .footer_downloads {
        display: flex;
        flex-direction: column;
        /* padding: 1% 0%; */
        margin-top: 28px;
    }
    
    
    
    .download_app_text .footer_downloads .footer_downloads_item {
       display: flex;
       align-items: flex-start;
       flex-direction: column;
       width: auto;
       margin-bottom: 20px;
       
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_title {
       color: var(--yellow);
       font-size: 1.15rem;
       margin-bottom: 5px;
       letter-spacing: .5px;
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_links .google_play {
       margin-right: 4px;
       width: 125.17px;
       height: 40.79px;
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_links .app_store {
       margin-right: 4px;
       width: 110.17px;
       height: 40.79px;
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .download_app {
        height: auto;
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 18% 0;
    }
    
    .download_app_content {
        width: 80%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
    }
    
    .download_app_content .download_app_photo img {
        height: 27rem;
        width: 20.5rem;
        margin-bottom: 40px;
    }
    
    .download_app_content .download_app_text {
        width: 80%;
    }

    .download_app_content .download_app_text .download_app_text_heading {
        font-size: 2.2rem;
        color: var(--white);
        margin-bottom: 10px;
        letter-spacing: 2px;
    }
    
    .download_app_content .download_app_text .download_app_text_sub {
        color: var(--lightgrey);
        font-weight: 400;
        font-size: 1.5rem;
        letter-spacing: 1.5px;
    }
    
    .download_app_text .footer_downloads {
        display: flex;
        flex-direction: column;
        /* padding: 1% 0%; */
        margin-top: 28px;
    }
    
    
    
    .download_app_text .footer_downloads .footer_downloads_item {
       display: flex;
       align-items: flex-start;
       flex-direction: column;
       width: auto;
       margin-bottom: 20px;
       
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_title {
       color: var(--yellow);
       font-size: 1.2rem;
       margin-bottom: 10px;
       letter-spacing: 2px;
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_links .google_play {
       margin-right: 6px;
       width: 148.17px;
       height: 54.79px;
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_links .app_store {
       margin-right: 4px;
       width: 145.17px;
       height: 54.79px;
    }
}
.thought_container {
    padding: 5.6% 0 0% 0;
    -webkit-transform: translateY(-3.6rem);
            transform: translateY(-3.6rem);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.thought_container .thought_content {
    width: 63.5%;
}

.thought_text {
    color: var(--white);
    font-size: 40px;
    line-height: 50px;
    margin-left: 5px;
    letter-spacing: 2px;
    margin-bottom: 34px;
    font-weight: 100;
}

a {
    text-decoration: none;
}

.thought_container .thought_card_container {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-between;
    /* align-items: center; */
    padding: 1.5% 0;
}

.thought_container .thought_card_container .thought_card {
    background-color: var(--white);
    border-radius: 20px 20px 20px 20px;
    box-shadow: 0px 2px 0px var(--lightblue);
    width: 578px;
    min-height: 100%;
    margin-bottom: 30px;
}

.thought_container .thought_card_container .thought_card .thought_card_img img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

.thought_container .thought_card_container .thought_card .thought_card_img {
    height: 318px;
}

.thought_container .thought_card_container .thought_card .thought_card_info {
    padding: 4.4% 7%;
}

.thought_container .thought_card_container .thought_card .thought_card_info p {
    margin-bottom: 0;
}


.thought_container .thought_card_container .thought_card .thought_card_info .thought_card_text {
    color: #069F03;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 1px;
}

.thought_container .media_content {
    width: 63.5%;
    margin-top: 4rem;
}


.thought_container .media_card_container {
    margin-top: 2.38rem;
}

.thought_container .media_card {
    background: var(--white);
    box-shadow: 0px 2px 0px var(--lightblue);
    border-radius: 20px;
    padding: 3% 8.2% 1.5% 8.2%;
    height: auto;
} 

.thought_container .media_card_container .media_card .media_card_img {
    width: 265px;
    height: 80px;
}

.thought_container .media_card_container .media_card .media_card_img img {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.thought_container .media_card_container .media_card .media_card_text {
    align-items: center;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.thought_container .media_card_container .media_card .media_card_text .media_card_heading {
    color: var(--darkblue);
    font-weight: 500;
    font-size: 44px;
    line-height: 40px;
    width: 50%;
}

.thought_container .media_card_container .media_card .media_card_text .media_card_para {
    color: var(--lightgrey);
    font-weight: 200;
    font-size: 28px;
    line-height: 40px;
    font-style: italic;
    width: 50%;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1)  {
    .thought_container {
        padding: 5.6% 0 0% 0;
        -webkit-transform: translateY(-3.6rem);
                transform: translateY(-3.6rem);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .thought_container .thought_content {
        width: 63.5%;
    }
    
    .thought_text {
        color: var(--white);
        font-size: 1.77rem;
        line-height: 37px;
        margin-left: 5px;
        letter-spacing: 1px;
    }
    
    .thought_container .thought_card_container {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        /* align-items: center; */
        padding: 1.5% 0;
    }
    
    .thought_container .thought_card_container .thought_card {
        background-color: var(--white);
        border-radius: 10px 10px 10px 10px;
        box-shadow: 0px 2px 0px var(--lightblue);
        width: 48.5%;
        min-height: 100%;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_img img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 10px 10px 0px 0px;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_img {
        height: 14.2rem;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_info {
        padding: 4.4% 7%;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_info .thought_card_text {
        color: #069F03;
        font-size: 1.4rem;
        line-height: 26px;
        letter-spacing: 0.8px;
    }
    
    .thought_container .media_content {
        width: 63.5%;
        margin-top: 4rem;
    }
    
    
    .thought_container .media_card_container {
        margin-top: 2.38rem;
    }
    
    .thought_container .media_card {
        background: var(--white);
        box-shadow: 0px 2px 0px var(--lightblue);
        border-radius: 20px;
        padding: 3% 8.2% 1.5% 8.2%;
        height: auto;
    } 
    
    .thought_container .media_card_container .media_card .media_card_img {
        height: 65px;
        width: 28%;
    }
    
    .thought_container .media_card_container .media_card .media_card_img img {
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
    
    .thought_container .media_card_container .media_card .media_card_text {
        align-items: center;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }
    
    .thought_container .media_card_container .media_card .media_card_text .media_card_heading {
        color: var(--darkblue);
        font-weight: 500;
        font-size: 1.62rem;
        letter-spacing: 0px;
        margin-bottom: 0;
        line-height: 28px;
    }
    
    .thought_container .media_card_container .media_card .media_card_text .media_card_para {
        color: var(--lightgrey);
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 28px;
        letter-spacing: 0px;
    }
}

@media (max-width: 600px) {
    .thought_container {
        padding-top: 16%;
        -webkit-transform: translateY(-1.6rem);
                transform: translateY(-1.6rem);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .thought_container .thought_content {
        width: 85%;
    }
    
    .thought_text {
        font-size: 1.5rem;
        color: var(--white);
        line-height: 30px;
        margin-left: 5px;
        letter-spacing: 1px;
        width:100%;
    }
    
    .thought_container .thought_card_container {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        /* align-items: center; */
        /* padding: 1.5% 0; */
    }
    
    .thought_container .thought_card_container .thought_card {
        background-color: var(--white);
        border-radius: 15px;
        box-shadow: 0px 2px 0px var(--lightblue);
        width: 100%;
        height: auto;
        margin-bottom: 30px;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_img img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 15px 15px 0px 0px;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_img {
        height: 10.2rem;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_info {
        padding: 2.4% 7%;
        padding-top: 5.5%;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_info .thought_card_text {
        color: #069F03;
        font-size: 1rem;
        line-height: 20px;
        letter-spacing: 0.5px;
    }
    
    .thought_container .media_content {
        width: 85%;
        margin-top: 2rem;
    }
    
    
    .thought_container .media_card_container {
        margin-top: 2.2rem;
    }
    
    .thought_container .media_card {
        background: var(--white);
        box-shadow: 0px 2px 0px var(--lightblue);
        border-radius: 15px;
        padding: 8.2%;
        height: auto;
    } 
    
    .thought_container .media_card_container .media_card .media_card_img {
        height: 70px;
        width: 60%;
    }
    
    .thought_container .media_card_container .media_card .media_card_img img {
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
    
    .thought_container .media_card_container .media_card .media_card_text {
        margin-top: 20px;
        flex-direction: column;

    }
    
    .thought_container .media_card_container .media_card .media_card_text .media_card_heading {
        color: var(--darkblue);
        font-weight: 500;
        font-size: 1.35rem;
        line-height: 26px;
        letter-spacing: 0px;
        width: 100%;
    }
    
    .thought_container .media_card_container .media_card .media_card_text .media_card_para {
        color: var(--lightgrey);
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 24px;
        letter-spacing: 0px;
        width: 100%;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .thought_container {
        padding: 16% 0;
        -webkit-transform: translateY(-1.6rem);
                transform: translateY(-1.6rem);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .thought_container .thought_content {
        width: 80%;
    }
    
    .thought_text {
        font-size: 2.2rem;
        color: var(--white);
        line-height: 42px;
        margin-left: 5px;
        letter-spacing: 2px;
        width:100%;
    }
    
    .thought_container .thought_card_container {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        /* align-items: center; */
        /* padding: 1.5% 0; */
    }
    
    .thought_container .thought_card_container .thought_card {
        background-color: var(--white);
        box-shadow: 0px 2px 0px var(--lightblue);
        width: 100%;
        height: auto;
        margin-bottom: 40px;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_img img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_img {
        height: 20.2rem;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_info {
        padding: 4.4% 7%;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_info .thought_card_text {
        color: #069F03;
        font-size: 2rem;
        line-height: 34px;
        letter-spacing: 1px;
    }
    
    .thought_container .media_content {
        width: 77%;
        margin-top: 2.5rem;
    }
    
    
    .thought_container .media_card_container {
        margin-top: 2.2rem;
    }
    
    .thought_container .media_card {
        background: var(--white);
        box-shadow: 0px 2px 0px var(--lightblue);
        padding: 8.2%;
        height: auto;
    } 
    
    .thought_container .media_card_container .media_card .media_card_img {
        height: 80px;
        width: 50%;
    }
    
    .thought_container .media_card_container .media_card .media_card_img img {
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
    
    .thought_container .media_card_container .media_card .media_card_text {
        margin-top: 20px;
    }
    
    .thought_container .media_card_container .media_card .media_card_text .media_card_heading {
        color: var(--darkblue);
        font-weight: bold;
        font-size: 2rem;
        line-height: 36px;
        letter-spacing: 0px;
    }
    
    .thought_container .media_card_container .media_card .media_card_text .media_card_para {
        color: var(--lightgrey);
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 28px;
        letter-spacing: 0px;
    }
  }
.iframe {
    pointer-events: none;
    width: 100%;
    position: absolute;
    top: 0;
    height: 80%;
    /* opacity: 0.1; */
}

header {
    height: 830px;
	width: 100%;
    /* background-image: url(''); */
	/* background-image: linear-gradient(#04072690, #04072690), url('./hero.jpg'); */
	/* background-image: linear-gradient(rgba(62, 126, 246,0.3),rgba(62, 126, 246,0.3)), url('./study.jpg'); */
	background-repeat: no-repeat;
	background-size: 100% 100%;
	/*clip-path: polygon(100% 0%, 100% 100%, 50% 100%, 0 100%, 0 0);*/
	position: relative;
	color: #fff;
    padding-bottom: 69.25%;
    top: -4.4rem;
    display: flex;
    justify-content: center;
}

header .videoheader_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .videoheader_text {
    -webkit-transform: translate(12rem, 30rem);
            transform: translate(12rem, 30rem);
    /* width: 100%; */
}

header .videoheader_text:last-child {
    -webkit-transform: translate(-100%, 30rem);
            transform: translate(-100%, 30rem);
}


.videoheader_text .h1 {
    width: 40%;
    font-size: 3.8rem;
    font-weight: 100!important;
}

.videoheader_text .h1 span {
    color: var(--yellow);
    font-weight: 100;
}

.videoheader_text .span {
    color: var(--lightgrey);
    font-weight: 100;
    width: 45%;
    font-size: 1.5rem;
    letter-spacing: 1px;
}

.videoheader_text button {
    padding: 1% 2%;
    margin-top: 1.5%;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    width: 16.5%;
    text-align: center;
    transition: background-color 0.5s ease-in-out;
}

.videoheader_text button .money_icon {
    width: 23.36px;
    height: 28px;
}

.videoheader_text button .arrow_right {
    width: 18px;
    height: 18px;
}

@media (max-width: 600px){
    .iframe {
        object-fit: cover;
    }

    header .videoheader_text {
        -webkit-transform: translate(1.6rem, 26%);
                transform: translate(1.6rem, 26%);
        width: 85%;
        text-align: center;
    }
    
    header .videoheader_text:last-child {
        display: none;
    }
    .videoheader_text .h1 {
        width: 100%;
        font-size: 1.6rem;
        font-weight: 100!important;
        margin-bottom: 16px;
        letter-spacing: 1.5px;
        line-height: 2.4rem;
    }
    
    .videoheader_text .span {
        color: var(--lightgrey);
        font-weight: 100;
        width: 100%;
        font-size: 1rem;
        margin-bottom: 20px;
        letter-spacing: 1px;
    }
    
    .videoheader_text button {
        padding: 2.2% 3%;
        margin-top: 1.5%;
        font-size: 13px;
        width: 68%;
        letter-spacing: 1px;
        font-weight: bold;
    }

    header {
        height: 785px;
    }

    .videoheader_text button .money_icon {
        width: 16.36px;
        height: 21px;
    }
    
    .videoheader_text button .arrow_right {
        width: 10px;
        height: 10px;
        margin-left: 15px;
    }
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1)  {
    .iframe {
        pointer-events: none;
        width: 100%;
        top: 0;
        height: 80%;
        /* opacity: 0.1; */
    }
    
    header {
        height: 952px;
        width: 100%;
        /*background-image: linear-gradient(rgba(0,168,255,0.3), rgba(0,168,255,0.3)), url('./co.jpg');*/
        /* background-image: linear-gradient(rgba(62, 126, 246,0.3),rgba(62, 126, 246,0.3)), url('./study.jpg'); */
        background-repeat: no-repeat;
        background-size: 100% 70%;
        /*clip-path: polygon(100% 0%, 100% 100%, 50% 100%, 0 100%, 0 0);*/
        position: relative;
        color: #fff;
        /* padding-bottom: 70.25%; */
        top: -4.4rem;
        display: flex;
        justify-content: center;
    }

    
    header .videoheader_text {
        /* position: absolute;
        z-index: 1; */
        /* top: 16.7rem;  */
        -webkit-transform: translate(8.5rem, 110%);
                transform: translate(8.5rem, 110%);
        /* left: 18.8%; */
    }

    header .videoheader_text:last-child {
        /* position: absolute;
        z-index: 1; */
        /* top: 16.7rem;  */
        -webkit-transform: translate(-100%, 200%);
                transform: translate(-100%, 200%);
        /* left: 18.8%; */
    }

    header .videoheader_text:last-child div {
        height: 158px!important;
        width: 154px!important;
    }
    
    .videoheader_text .h1 {
        width: 40%;
        font-size: 2.69rem;
        font-weight: 100!important;
        letter-spacing: 0.5px;
    }
    
    .videoheader_text .h1 span {
        color: var(--yellow);
        letter-spacing: 0.5px;
    }
    
    .videoheader_text .span {
        color: var(--lightgrey);
        font-weight: 100;
        width: 45%;
        font-size: 1.05rem;
        letter-spacing: 0.5px;
    }
    
    .videoheader_text button {
        padding: 0.8% 2%;
        margin-top: 1.5%;
        font-size: 14px;
        width: 18%;
    }
    
    .videoheader_text button .money_icon {
        width: 23.36px;
        height: 28px;
    }
    
    .videoheader_text button .arrow_right {
        width: 14px;
        height: 14px;
    }
    
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1)  {
    .iframe {
        object-fit: cover;
    }

    header .videoheader_text {
        -webkit-transform: translate(5.3rem, 12rem);
                transform: translate(5.3rem, 12rem);
        width: 75%;
    }
    
    header .videoheader_text:last-child {
        display: none;
    }
    .videoheader_text .h1 {
        width: 100%;
        font-size: 1.5rem;
        font-weight: 100!important;
        margin-bottom: 16px;
        letter-spacing: 1px;
    }
    
    .videoheader_text .span {
        color: var(--lightgrey);
        font-weight: 100;
        width: 90%;
        font-size: 1.2rem;
        margin-bottom: 20px;
        line-height: 20px;
        letter-spacing: 0.5px;
    }
    
    .videoheader_text button {
        padding: 1.8% 5%;
        margin-top: 1.5%;
        font-size: 18px;
        width: 50%;
        letter-spacing: 1px;
        font-weight: bold;
    }

    header {
        height: 675px;
    }

    .videoheader_text button .money_icon {
        width: 16.36px;
        height: 21px;
    }
    
    .videoheader_text button .arrow_right {
        width: 10px;
        height: 10px;
        margin-left: 15px;
    }
}
.watch_video {
    background-image: url(../../static/media/watch_video_img.eca7f108.png);
    background-size: 100%;
    height: 37rem;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.watch_video .watch_video_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.watch_video .watch_video_text p {
    color: #069F03;
    font-size: 70px;
    line-height: 50px;
    letter-spacing: 2px;
}

.watch_video .watch_video_text button {
    padding: 17px 41px;
    margin-top: 20px;
    width: auto;
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-right: 20px;
}

.watch_video .watch_video_btn {
    display: flex;
    justify-content: center;
}

.watch_video .watch_video_btn button:last-child {
    background: var(--white)!important;
}

.watch_video .watch_video_btn button:last-child:hover {
    color: var(--yellow)!important;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1)  {
    .watch_video {
        background-image: url(../../static/media/watch_video_img.eca7f108.png);
        background-size: 100%;
        height: 37rem;
    }
    
    .watch_video .watch_video_text p {
        color: #069F03;
        font-size: 4rem;
        letter-spacing: 0px;
    }
    
    .watch_video .watch_video_text button {
        padding: 2%;
        margin-top: 15px;
        width: 14.5rem;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        margin-right: 20px;
    }

    .watch_video .watch_video_btn button:last-child {
        width: 12.5rem;
    }
}

@media (max-width: 600px) {
    .watch_video {
        background-image: url(../../static/media/watch_video_img.eca7f108.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 16rem;
    }
    
    .watch_video .watch_video_text p {
        color: #069F03;
        font-size: 1.6rem;
        letter-spacing: 0px;
        margin-bottom: 3px;
    }
    
    .watch_video .watch_video_text button {
        padding: 3%;
        margin-top: 5px;
        width: 60%;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        margin-bottom: 10px!important;
        width: 70%;
    }

    .watch_video .watch_video_btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .watch_video .watch_video_btn button:last-child {
        background: var(--white)!important;
        width: 70%;
    }

    .watch_video .watch_video_btn button {
        margin-left: 0!important;
        margin-right: 0!important;
        
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .watch_video {
        background-image: url(../../static/media/watch_video_img.eca7f108.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 22rem;
    }

    .watch_video .watch_video_text {
        /* position: absolute; */
        padding-top: 15.5%;
        padding-left: 10.6%;
    }
    
    .watch_video .watch_video_text p {
        color: #069F03;
        font-size: 2.2em;
        letter-spacing: 1px;
        margin-bottom: 14px;
    }
    
    .watch_video .watch_video_text button {
        padding: 3% 2%;
        margin-top: 8px;
        width: 15.5rem;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 2px;
    }
}
.footer {
    background-color: #040726;
    /* background-image: url('./footer_bg.svg'); */
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

.footer .bg_img {
    display: none;
}

.footer .footer_body {
    margin-top: 4%;
    width: 63%;
}

.footer a {
    color: var(--yellow);
}
.footer .footer_body .logo_slogn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-bottom: 4%; */
    /* border-bottom: 2px solid rgba(73, 74, 126, 0.5); */
}

.footer .footer_body .logo_slogn .footer_logo {
    width: 161.22px;
    height: 160.56px;
    border-radius: 200px;
    /* animation: glow 5s linear infinite; */
}

.footer .footer_body .logo_slogn .footer_logo div {
    margin: 0px 0px!important;
}

.footer .footer_body .logo_slogn .slogan {
    width: 550px;
    height: 57.7px;
    margin-bottom: 30px;
    margin-left: auto;
}

.footer hr {
    background-color: var(--lightblue);
    height: 2px;
    width: 100%;
}

.footer .footer_links_container {
   width: 63%;
}

.footer .footer_links_container .footer_links {
    display: flex;
    justify-content: space-between;
    padding: 2.2% 0%;
    
}

.footer .footer_links_container .footer_links .footer_link_item {
    width: 200px;
}


.footer .footer_links_container .footer_links .footer_link_item .link_title {
    font-weight: 100;
    font-size: 24px;
    line-height: 28px;
    color: var(--white);
    letter-spacing: 2px;
}

.footer .footer_links_container .footer_links .footer_link_item .link_links {
    display: flex;
    flex-direction: column;
}

.footer .footer_links_container .footer_links .footer_link_item .link_link {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: var(--lightgrey);
    text-decoration: none;
    margin-bottom: 16px;
    letter-spacing: 1px;
}

.footer .footer_links_container .footer_links .footer_link_item .link_link:hover {
    color: #C29200!important;
}

.footer .footer_socials_container .footer_socials .footer_socials_item_link .social_link:hover {
    color: #C29200!important;
}

.footer .footer_socials_container {
    width: 63%;
 }
 
 .footer .footer_socials_container .footer_socials {
     display: flex;
     justify-content: space-between;
     padding: 1% 0%;
     
 }
 
 .footer .footer_socials_container .footer_socials .footer_socials_item_icon {
     display: flex;
     justify-content: space-between;
     align-items: center;
     width: 22%;
 }

 .footer .footer_socials_container .footer_socials .footer_socials_item_icon a {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer .footer_socials_container .footer_socials .footer_socials_item_icon a:hover {
    opacity: 0.9;
}

.footer .copyright a:hover {
    opacity: 0.9;
}

 .footer .footer_socials_container .footer_socials .footer_socials_item_icon .facebook_icon {
    height: 30px;
    width: 18px;
}

.footer .footer_socials_container .footer_socials .footer_socials_item_icon .youtube_icon {
    height: 42px;
    width: 29.44px;
    
}

.footer .footer_socials_container .footer_socials .footer_socials_item_icon .youtube_icon[src="/assets/youtube.svg"] {
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
}


 .footer .footer_socials_container .footer_socials .footer_socials_item_link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 36%;
}

.footer .footer_socials_container .footer_socials .footer_socials_item_link .social_link {
    color: var(--lightgrey);
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 2px;
}

/* .footer .footer_socials_container .footer_socials .footer_socials_item_icon img {
    
    width: 42px;
    height: 29.44px;
} */


.footer .footer_downloads_container {
    width: 63%;
    padding-bottom: 1%;
 }
 
 .footer .footer_downloads_container .footer_downloads {
     display: flex;
     justify-content: flex-start;
     padding: 1% 0%;
     
 }
 
 .footer .footer_downloads_container .footer_downloads .footer_downloads_item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: auto;
    margin-right: 37px;
    margin-left: 2px;
}

.footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_title {
    color: var(--yellow);
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 2px;
}

.footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .google_play {
    margin-right: 4px;
    width: 124.17px;
    height: 35.79px;
}

.footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .app_store {
    margin-right: 4px;
    width: 102.33px;
    height: 35.79px;
}

.footer .copyright_container {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
}

.footer .copyright_container .copyright {
    color: var(--lightgrey);
    font-size: 18px;
    line-height: 21px;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .footer {
        background-color: #040726;
        /* background-image: url('./footer_bg.svg'); */
        /* height: 1454px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    
    /* .footer .bg_img {
        width: 100%;
        position: relative;
    } */
    
    .footer .footer_body {
        margin-top: 4%;
        width: 63%;
    }
    
    .footer .footer_body .logo_slogn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* padding-bottom: 0.5%; */
        /* border-bottom: 2px solid rgba(73, 74, 126, 0.5); */
    }
    
    .footer .footer_body .logo_slogn .footer_logo {
        height: 108px;
        width: 114px;
    }
    
    .footer .footer_body .logo_slogn .slogan {
        height: 140px;
        width: 57%;
    }
    
    .footer hr {
        background-color: var(--lightblue);
        height: 2px;
        width: 100%;
    }
    
    .footer .footer_links_container {
       width: 63%;
    }
    
    .footer .footer_links_container .footer_links {
        display: flex;
        justify-content: space-between;
        padding: 2.2% 0%;
        
    }
    
    .footer .footer_links_container .footer_links .footer_link_item {
        width: 170px;
    }
    
    
    .footer .footer_links_container .footer_links .footer_link_item .link_title {
        font-weight: 400;
        color: var(--white);
        letter-spacing: 1px;
        font-size: 1.2rem;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item .link_links {
        display: flex;
        flex-direction: column;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item .link_link {
        font-weight: 500;
        color: var(--lightgrey);
        text-decoration: none;
        letter-spacing: 1px;
        font-size: 1rem;
        margin-bottom: 8px;
    }
    
    .footer .footer_socials_container {
        width: 63%;
     }
     
     .footer .footer_socials_container .footer_socials {
         display: flex;
         justify-content: space-between;
         padding: 1% 0%;
         
     }
     
     .footer .footer_socials_container .footer_socials .footer_socials_item_icon {
         display: flex;
         justify-content: space-between;
         align-items: center;
         width: 30%;
     }
    
     .footer .footer_socials_container .footer_socials .footer_socials_item_link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 38.5%;
    }
    
    .footer .footer_socials_container .footer_socials .footer_socials_item_link .social_link {
        color: var(--lightgrey);
        font-size: 0.9rem;
        letter-spacing: 1px;
        font-weight: 500;
    }
    
    .footer .footer_socials_container .footer_socials .footer_socials_item_icon .facebook_icon {
        height: 28px;
        width: 16px;
    }
    
    .footer .footer_socials_container .footer_socials .footer_socials_item_icon .youtube_icon {
        height: 40px;
        width: 27.44px;
    }
    
    
    .footer .footer_downloads_container {
        width: 63%;
        padding-bottom: 1%;
     }
     
     .footer .footer_downloads_container .footer_downloads {
         display: flex;
         justify-content: flex-start;
         padding: 1% 0%;
         
     }
     
     .footer .footer_downloads_container .footer_downloads .footer_downloads_item {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: auto;
        margin-right: 37px;
        margin-left: 2px;
        letter-spacing: 0px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_title {
        color: var(--yellow);
        font-size: 0.8rem;
        margin-bottom: 1px;
        letter-spacing: 0.5px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .google_play {
        margin-right: 4px;
        width: 84.17px;
        height: 25.79px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .app_store {
        margin-right: 4px;
        width: 74.17px;
        height: 25.79px;
    }
    
    .footer .copyright_container {
        margin-top: 14px;
    }
    
    .footer .copyright_container .copyright {
        color: var(--lightgrey);
        font-size: 0.8rem;
        letter-spacing: 0.5px;
    }
}

@media (max-width: 600px) {
    .footer {
        background-color: #040726;
        background-image: none;
        /* height: 1454px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        background-size: cover;
        background-position: 100% 0;
        background-repeat: no-repeat;
        position:relative;
        z-index:1;
        /* width:200px;
        height:200px; */
    }

    .footer:before{
        position:absolute;
        z-index:-1;
        bottom: 0;
        left:0;
        width:100%;
        height:80%;
        content:"";
        background-color: #040726;
    }
    
    .footer .bg_img {
        display: block;
        position: absolute;
        z-index: -1;
        width: 100vw;
        /* height: 110vh; */
    }

    .footer .footer_body .logo_slogn {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 140px;
        /* padding-bottom: 0.5%; */
        /* border-bottom: 2px solid rgba(73, 74, 126, 0.5); */
    }
    
    .footer .footer_body {
        margin-top: 12%;
        width: 80%;
    }

    .footer .footer_body .logo_slogn .footer_logo {
        height: 108px;
        width: 114px;
    }

    .footer .footer_body .logo_slogn .footer_logo div {
        height: 88px!important;
        width: 84px!important;
    }

    .footer .footer_body .logo_slogn .slogan {
        height: 100px;
        width: 100%;
        margin-bottom: 0px;
    }
    
    .footer hr {
        background-color: var(--lightblue);
        height: 2px;
        width: 100%;
    }
    
    .footer .footer_links_container {
       width: 80%;
    }
    
    .footer .footer_links_container .footer_links {
        display: flex;
        justify-content: space-between;
        padding: 2.2% 0%;
        flex-wrap: wrap;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item {
        width: 142px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    
    
    .footer .footer_links_container .footer_links .footer_link_item .link_title {
        font-weight: 400;
        color: var(--white);
        letter-spacing: 1px;
        font-size: 1rem;
        line-height: 16px;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item .link_links {
        display: flex;
        flex-direction: column;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item .link_link {
        font-weight: 400;
        color: var(--lightgrey);
        text-decoration: none;
        letter-spacing: 0.8px;
        font-size: 0.8rem;
        margin-bottom: 8px;
    }
    
    .footer .footer_socials_container {
        width: 80%;
     }
     
     .footer .footer_socials_container .footer_socials {
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         padding: 1% 0%;

     }
     
     .footer .footer_socials_container .footer_socials .footer_socials_item_icon {
         display: flex;
         justify-content: space-between;
         align-items: center;
         width: 80%;
         margin-bottom: 20px;

     }
    
     .footer .footer_socials_container .footer_socials .footer_socials_item_link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    
    .footer .footer_socials_container .footer_socials .footer_socials_item_link .social_link {
        color: var(--lightgrey);
        font-size: 0.8rem;
        letter-spacing: 0.5px;
        margin-right: 0px;
    }

    .footer .footer_socials_container .footer_socials .footer_socials_item_icon .facebook_icon {
        height: 26px;
        width: 14px;
    }
    
    .footer .footer_socials_container .footer_socials .footer_socials_item_icon .youtube_icon {
        height: 21px;
        width: 28.44px;
    }
    
    
    .footer .footer_downloads_container {
        width: 80%;
        padding-bottom: 1%;
     }
     
     .footer .footer_downloads_container .footer_downloads {
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         padding: 1% 0%;

     }
     
     .footer .footer_downloads_container .footer_downloads .footer_downloads_item {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        margin-right: 30px;
        margin-left: 2px;
        letter-spacing: 0px;
        margin-bottom: 10px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_title {
        color: var(--yellow);
        font-size: 0.7rem;
        margin-bottom: 3px;
        letter-spacing: 1px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .google_play {
        margin-right: 6px;
        width: 84.17px;
        height: 26.79px;
        margin-bottom: 5px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .app_store {
        margin-right: 4px;
        width: 74.17px;
        height: 26.79px;
    }
    
    .footer .copyright_container {
        margin-top: 14px;
        flex-direction: column;
    }
    
    .footer .copyright_container .copyright {
        color: var(--lightgrey);
        font-size: 0.8rem;
        letter-spacing: 0.5px;
    }

}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .footer {
        background-color: var(--darkblue);
        background-image: none;
        /* height: 1454px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        background-size: cover;
        background-position: 100% 0;
        background-repeat: no-repeat;
        position:relative;
        z-index:1;
        /* width:200px;
        height:200px; */
    }

    .footer:before{
        position:absolute;
        z-index:-1;
        bottom: 0;
        left:0;
        width:100%;
        height:80%;
        content:"";
        background-color: #040726;
    }
    
    .footer .bg_img {
        display: block;
        position: absolute;
        z-index: -1;
        width: 100vw;
        /* height: 110vh; */
    }

    .footer .footer_body .logo_slogn {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        /* padding-bottom: 0.5%; */
        /* border-bottom: 2px solid rgba(73, 74, 126, 0.5); */
    }
    
    .footer .footer_body {
        margin-top: 38%;
        width: 80%;
    }
    
    .footer .footer_body .logo_slogn .footer_logo {
        height: 108px;
        width: 114px;
    }

    .footer .footer_body .logo_slogn .slogan {
        height: 100px;
        width: 100%;
        margin-bottom: 0px;
    }
    
    .footer hr {
        background-color: var(--lightblue);
        height: 2px;
        width: 100%;
    }
    
    .footer .footer_links_container {
       width: 80%;
    }
    
    .footer .footer_links_container .footer_links {
        display: flex;
        justify-content: space-between;
        padding: 2.2% 0%;
        flex-wrap: wrap;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item {
        width: 40%;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    
    
    .footer .footer_links_container .footer_links .footer_link_item .link_title {
        font-weight: 400;
        color: var(--white);
        letter-spacing: 1.5px;
        font-size: 1.6rem;
        line-height: 20px;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item .link_links {
        display: flex;
        flex-direction: column;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item .link_link {
        font-weight: 400;
        color: var(--lightgrey);
        text-decoration: none;
        letter-spacing: 0.8px;
        font-size: 1.3rem;
        margin-bottom: 15px;
    }
    
    .footer .footer_socials_container {
        width: 80%;
     }
     
     .footer .footer_socials_container .footer_socials {
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         padding: 1% 0%;

     }
     
     .footer .footer_socials_container .footer_socials .footer_socials_item_icon {
         display: flex;
         justify-content: space-between;
         align-items: center;
         width: 22.5%;
         margin-bottom: 35px;

     }
    
     .footer .footer_socials_container .footer_socials .footer_socials_item_link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    
    .footer .footer_socials_container .footer_socials .footer_socials_item_link .social_link {
        color: var(--lightgrey);
        font-size: 1.3rem;
        letter-spacing: 1px;
        margin-right: 20px;
    }

    .footer .footer_socials_container .footer_socials .footer_socials_item_icon .facebook_icon {
        height: 34px;
        width: 22px;
    }
    
    .footer .footer_socials_container .footer_socials .footer_socials_item_icon .youtube_icon {
        height: 28px;
        width: 36.44px;
    }
    
    
    .footer .footer_downloads_container {
        width: 80%;
        padding-bottom: 1%;
     }
     
     .footer .footer_downloads_container .footer_downloads {
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         padding: 1% 0%;

     }
     
     .footer .footer_downloads_container .footer_downloads .footer_downloads_item {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        margin-right: 30px;
        margin-left: 2px;
        letter-spacing: 0px;
        margin-bottom: 20px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_title {
        color: var(--yellow);
        font-size: 1.2rem;
        margin-bottom: 10px;
        letter-spacing: 2px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .google_play {
        margin-right: 10px;
        width: 128.17px;
        height: 45.79px;
        margin-bottom: 5px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .app_store {
        margin-right: 4px;
        width: 128.17px;
        height: 45.79px;
    }
    
    .footer .copyright_container {
        margin-top: 14px;
    }
    
    .footer .copyright_container .copyright {
        color: var(--lightgrey);
        font-size: 1.2rem;
        letter-spacing: 0.5px;
    }
}
.body {
    -webkit-transform: translateY(-30.7rem);
            transform: translateY(-30.7rem);
}

#homescreen {
    height: 110vw;
}

/* .footer {
    transform: translateY(-27rem);
} */

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1)  {
    .body {
        -webkit-transform: translateY(-26.7rem);
                transform: translateY(-26.7rem);
    }

    #homescreen {
        height: 110vw;
    }
}

@media (max-width: 600px) {
    .body {
        -webkit-transform: translateY(-19.7rem);
                transform: translateY(-19.7rem);
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .body {
        -webkit-transform: translateY(-19.7rem);
                transform: translateY(-19.7rem);
    }

}
.navbar_custom {
    background-color: var(--darkblue);
    /* height: 50%!important; */
    padding: 1.1% 4.5%;
    box-shadow: 0px 2px 1px var(--lightblue);
    z-index: 1;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
}

.navbar_custom img {
    width: 241px;
    height: 47.92px;
}

.navbar_custom .navbar-toggler {
    color: var(--white)!important;
}

.navbar_custom .navbar-toggler .navbar-toggler-icon {
    color: var(--white)!important;
}

.navbar_custom .nav-link {
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 100;
    color: #FFFFFF!important;
    padding-right: 0!important; 
    padding-left: 0!important;
    line-height: 12px;
}

.navbar_custom .nav-link:hover::after {
    width: 100%;
}

.navbar_custom .nav-link:hover {
    font-weight: 300!important;
    color: var(--yellow)!important;
}

.navbar_custom .active {
    font-weight: 300!important;
    color: var(--yellow)!important;
    border-bottom: 2px solid var(--yellow);
}

.navbar_custom .nav-link::after{
    content: '';
    display: block;
    /* margin-top: 10px; */
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    height: 2px;
    width: 0px;
    background: var(--yellow);
    transition: all 0.35s linear;
}

.navbar_custom .dropdown i {
    font-size: 0.8rem;
    margin-left: 5px;
}

.navbar_custom .dropdown .nav-link {
    display: flex;
    justify-content: space-between;
}

.navbar_custom .dropdown-menu {
    color: #FFFFFF;
    background-color: var(--darkblue);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.5rem;
    min-width: 5rem;
    text-align: center;
    box-shadow: 0px 1px 1px var(--lightblue);
}

.navbar_custom .dropdown-menu .dropdown-item {
    color: #FFFFFF;
    font-weight: 300;
}

.navbar_custom .dropdown-menu .dropdown-item:focus, 
.navbar_custom .dropdown-menu .dropdown-item:hover {
    font-weight: 300!important;
    color: var(--yellow)!important;
    background: none;
}

.navbar_custom .dropdown-menu .active, .navbar_custom .dropdown-menu .dropdown-item:active {
    color: none;
    text-decoration: none;
    background: none!important;
    border-bottom: 0px solid var(--yellow);
}

.navbar_custom .navbar-nav {
    width: 64.5%;
    justify-content: space-between;
}

.fas {
    font-size: 1.7rem;
}

.navbar-toggler {
    width: 60px;
    border: none;
    outline: none;
    height: 40px;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

@media (max-width: 600px){
    .navbar_custom {
        padding: 2% 2%;
    }

    .navbar-nav {
        margin-left: 0!important;
        padding: 4%;
        width: 90.5%!important;
    }

    .navbar_custom .nav-link {
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 18px;
        font-weight: 100;
    }

    .navbar_custom img {
        width: 130px;
        height: 27.92px;
    }

    .navbar_custom .active {
        border-bottom: 0px;
        font-weight: 300!important;
    }

    .navbar_custom .nav-link::after {
        display: none;
    }

    .fas {
        font-size: 1.4rem;
        font-weight: 700;
    }

    .navbar_custom .navbar-toggler {
        width: 40px;
    }

    .navbar_custom .dropdown-menu {
        min-width: 2rem;
        text-align: left;
    }
    
    .navbar_custom .dropdown .nav-link {
        display: flex;
        justify-content: normal;
        align-items: center;
    }

    .navbar_custom .dropdown i {
        font-size: 0.6rem;
        margin-left: 10px;
    }
}


@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .navbar_custom {
        padding: 1.1% 4.5%;
    }

    .navbar_custom .nav-link {
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 10px;
        font-weight: 100;
    }

    .navbar_custom img {
        width: 170px;
        height: 38.92px;
    }

    .navbar_custom .navbar-nav {
        width: 63.5%!important;
        justify-content: space-between;
    }

    .navbar_custom .dropdown i {
        font-size: 0.5rem;
        margin-left: 5px;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1){
    .navbar_custom {
        padding: 2% 4.5%;
    }

    .navbar-nav {
        margin-left: 0!important;
        padding: 3%;

    }

    .navbar_custom .nav-link {
        font-size: 20px;
        letter-spacing: 3px;
        line-height: 30px;
        font-weight: 100;
    }

    .navbar_custom img {
        width: 228px;
        height: 62.92px;
    }

    .navbar_custom .active {
        border-bottom: 0px;
        font-weight: 300!important;
    }

    .navbar_custom .nav-link::after {
        display: none;
    }

    .fas {
        font-size: 1.4rem;
    }
}
.faq_question_container {
    border-top: 1px solid rgba(73, 74, 126, 0.5);
    width: 100%;
    padding: 2.5% 0 1.8% 0;
    /* background-color: #fff; */
}

.faq_question_container:last-child {
    border-bottom: 1px solid rgba(73, 74, 126, 0.5);
}

.faq_question_container .faq_question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.faq_question_container .faq_question img {
    width: 32px;
    height: 20px;
}

.faq_question_container .faq_question p {
    font-weight: 300;
    color: var(--white);
    margin-bottom: 0!important;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 1px;
}


.faq_question_container .active p {
    color: var(--yellow);
}

.faq_question_container .faq_answer {
    color: #E2E2E2;
    margin-left: 5.5%;
    margin-right: 20%;
    margin-top: 20px;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 2px;
    font-weight: 100;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .faq_question_container {
        border-top: 1px solid rgba(73, 74, 126, 0.5);
        
        width: 100%;
        padding: 2.5% 0 1.8% 0;
        /* background-color: #fff; */
    }
    
    .faq_question_container:last-child {
        border-bottom: 1px solid rgba(73, 74, 126, 0.5);
    }

    .faq_question_container .faq_question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    
    .faq_question_container .faq_question img {
        width: 28px;
        height: 16px;
    }
    
    .faq_question_container .faq_question p {
        font-weight: 300;
        color: var(--white);
        margin-bottom: 0!important;
        font-size: 22px;
        line-height: 40px;
        letter-spacing: 0.5px;
    }
    
    
    .faq_question_container .active p {
        color: var(--yellow);
    }
    
    .faq_question_container .faq_answer {
        color: #E2E2E2;
        margin-left: 5.5%;
        margin-right: 20%;
        margin-top: 20px;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 1px;
    }

    .faq_question_container .faq_answer p {
        margin-bottom: 0!important
    }
}

@media (max-width: 600px) {
    .faq_question_container {
        border-top: 1px solid rgba(73, 74, 126, 0.5);
        width: 100%;
        padding: 5.5% 0 5.8% 0;
        /* background-color: #fff; */
    }

    .faq_question_container:last-child {
        border-bottom: 1px solid rgba(73, 74, 126, 0.5);
    }
    
    .faq_question_container .faq_question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    
    .faq_question_container .faq_question img {
        width: 24px;
        height: 12px;
        margin-left: auto;
    }

    .faq_question_container .faq_question .arrow_right_faq img {
        width: 12px!important;
        height: 12px!important;
    }

    .faq_question_container .faq_question .arrow_right_faq {
        width: 6%!important;
    }
    
    .faq_question_container .faq_question p {
        font-weight: 300;
        color: var(--white);
        margin-bottom: 0!important;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        margin-left: 20px;
        font-size: 100;
        width: 90%;
    }
    
    
    .faq_question_container .active p {
        color: var(--yellow);
        font-size: 16px;
    }

    .faq_question_container .faq_answer {
        margin-right: 2%;
    }
    
    .faq_question_container .faq_answer p {
        color: #E2E2E2;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 20px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1.5px;
        font-weight: 100;
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .faq_question_container {
        border-top: 2px solid rgba(73, 74, 126, 0.5);
        border-bottom: 1px solid rgba(73, 74, 126, 0.5);
        width: 100%;
        padding: 2.5% 0 1.8% 0;
        /* background-color: #fff; */
    }
    
    .faq_question_container .faq_question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    
    .faq_question_container .faq_question img {
        width: 28px;
        height: 16px;
    }
    
    .faq_question_container .faq_question p {
        font-weight: 300;
        color: var(--white);
        margin-bottom: 0!important;
        font-size: 22px;
        line-height: 40px;
        letter-spacing: 0.5px;
    }
    
    
    .faq_question_container .active p {
        color: var(--yellow);
    }
    
    .faq_question_container .faq_answer p {
        color: var(--grey);
        margin-left: 5.5%;
        margin-right: 20%;
        margin-top: 20px;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.5px;
    }
}
.faq {
    display: flex;
    justify-content: center;
    align-items: center;
}

.faq .faq_content {
    width: 65.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faq .faq_content .faq_btn_container {
    display: flex;
    justify-content: space-between;
    width: 43%;
    margin-bottom: 44px;
}

.faq .faq_content .faq_btn_container .faq_btn {
    box-shadow: 0px 1px 0px var(--lightblue);
    border-radius: 8px;
    padding: 2.9% 0;
    width: 261px;
    font-size: 0.86rem;
    border: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    height: 60px;
}

/* .faq .faq_content .cabtain_tab {
    display: none;
} */
.faq .faq_content .faq_btn_container .active {
    background-color: #C29200;
    color: var(--white);
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .faq {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .faq .faq_content {
        width: 65.5%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .faq .faq_content .faq_btn_container {
        display: flex;
        justify-content: space-between;
        width: 43%;
        margin-bottom: 44px;
    }
    
    .faq .faq_content .faq_btn_container .faq_btn {
        box-shadow: 0px 1px 0px var(--lightblue);
        border-radius: 8px;
        padding: 2% 0;
        width: 180px;
        font-size: 0.86rem;
        border: none;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        height: 42px;
        letter-spacing: 0.5px;
    }
    
    /* .faq .faq_content .cabtain_tab {
        display: none;
    } */
    .faq .faq_content .faq_btn_container .active {
        background-color: #C29200;
        color: var(--white);
    }
}

@media (max-width: 600px) {
    .faq {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .faq .faq_content {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .faq .faq_content .faq_btn_container {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin-bottom: 44px;
    }
    
    .faq .faq_content .faq_btn_container .faq_btn {
        box-shadow: 0px 1px 0px var(--lightblue);
        border-radius: 8px;
        padding: 2% 0;
        width: 48%;
        border: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        height: 38px;
        letter-spacing: 0.5px;
    }
    
    /* .faq .faq_content .cabtain_tab {
        display: none;
    } */
    .faq .faq_content .faq_btn_container .active {
        background-color: #C29200;
        color: var(--white);
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .faq {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .faq .faq_content {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .faq .faq_content .faq_btn_container {
        display: flex;
        justify-content: space-between;
        width: 65%;
        margin-bottom: 44px;
    }
    
    .faq .faq_content .faq_btn_container .faq_btn {
        box-shadow: 0px 1px 0px var(--lightblue);
        border-radius: 8px;
        padding: 2% 0;
        width: 180px;
        font-size: 0.86rem;
        border: none;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        height: 42px;
        letter-spacing: 0.5px;
    }
    
    /* .faq .faq_content .cabtain_tab {
        display: none;
    } */
    .faq .faq_content .faq_btn_container .active {
        background-color: #C29200;
        color: var(--white);
    }
}
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8rem;
}

.header_content {
    display: flex;
    justify-content: space-between;
    width: 62.5%;
    padding: 5% 0;
}

.header_content .header_small {
    color: var(--yellow);
    font-size: 18px;
    line-height: 68px;
    font-weight: 400;
    letter-spacing: 2px;
}

.header_content .header_small span {
    text-transform: capitalize;
}

.header_content .header_main {
    margin-top: -18px!important;
    color: var(--white);
    font-size: 50px;
    line-height: 50px;
    font-weight: 300;
    width: 80%;
    letter-spacing: 1px;
}

.header_content .blog_header_date {
    color: var(--white);
    font-weight: 300;
    text-transform: uppercase;
}

.header_content .header_img {
    -webkit-transform: translateY(14px);
            transform: translateY(14px);
    width: 184.06px;
    height: 183.94px;
}


@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .header_content {
        display: flex;
        justify-content: space-between;
        width: 62.5%;
        padding: 5% 0;
    }
    
    .header_content .header_small {
        color: var(--yellow);
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 0.5px;
    }
    
    .header_content .header_main {
        margin-top: -30px!important;
        color: var(--white);
        font-size: 2.2rem;
        font-weight: 300;
        width: 80%;
        line-height: 38px;
    }
    
    .header_content .header_img {
        -webkit-transform: translateY(28px);
                transform: translateY(28px);
        height: 120px;
        width: 120px;
    }
}

@media (max-width: 600px) {
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
    }
    
    .header_content {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: flex-start;
        width: 85%!important;
        padding: 5% 0;
    }
    
    .header_content .header_small {
        color: var(--yellow);
        font-size: 1.2rem;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 28px;
        margin-bottom: 5px;
    }
    
    .header_content .header_img {
        -webkit-transform: translateY(0px)!important;
                transform: translateY(0px)!important;
        width: 68.06px;
        height: 67.84px;
        margin-bottom: 20px;
    }
    
    .header_content .header_main {
        margin-top: 6px!important;
        color: var(--white);
        font-size: 1.7rem;
        font-weight: 300;
        width: 100%!important;
        line-height: 32px;
        letter-spacing: 1px;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .header_content {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: flex-start;
        width: 80%;
        padding: 10% 0;
    }
    
    .header_content .header_small {
        color: var(--yellow);
        font-size: 1.5rem;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 28px;
        margin-bottom: 5px;
    }
    
    .header_content .header_main {
        margin-top: 0px!important;
        color: var(--white);
        font-size: 2.2rem;
        font-weight: 400;
        width: 100%;
        line-height: 40px;
        letter-spacing: 1.5px;
    }
    
    .header_content .header_img {
        -webkit-transform: translateY(0px)!important;
                transform: translateY(0px)!important; 
        width: 131.06px;
        height: 120.84px;
        margin-bottom: 20px;
    }
    
    .header_content .mobile_main {
        margin-top: 15px!important;
        color: var(--white);
        font-size: 1.8rem;
        font-weight: 400;
        width: 100%;
        line-height: 34px;
        letter-spacing: 0px;
    }
}
.header_card_content {
    background: none;
    /* position: relative; */
    /* top: 30px; */
    height: 295px;
    border-radius: 30px;
    padding: 10%;
    padding-top: 6%;
}

#cabtainservice .header_card_content {
    height: 225px;
}

.header_card {
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    padding-top: 41px;
    /* height: 16rem; */
    /* background-color: black; */
    /* z-index: 1; */
    height: auto;
    width: 32%;
    border: 3px solid #494A7E;
    border-radius: 20px;
}

/* .header_card .header_card_icon img {
    width: 90px; 
    height: 90px;
} */

.bargained_icon {
    width: 90.7px; 
    height: 90.7px;
    /* margin-top: 20px; */
}

.secure_icon {
    width: 90.7px; 
    height: 90.7px;
    margin-top: 0px;
}

.header_card .header_card_icon {
    width: 141px;
    height: 141px;
    background-color: var(--white);
    box-shadow: 0px 2px 0px rgba(47, 49, 96, 0.2);
    -webkit-transform: translateX(38px);
            transform: translateX(38px);
    /* position: absolute; */
    /* top: 100px; */
    z-index: 1;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.header_card_content .header_card_heading {
    color: var(--yellow);
    margin-bottom: 8px!important;
    font-weight: 100;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 1px;
}

.header_card_content .header_card_para {
    color: var(--white);
    font-weight: 100;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1px;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    /* .cabtm_container {
        height: 420px!important;
    } */

    .header_card_content {
        background: none;
        /* position: relative; */
        /* top: 30px; */
        height: 275px;
        border-radius: 30px;
        padding: 10%;
        
    }
    
    .header_card {
        padding-top: 1.8%;
        /* height: 16rem; */
        /* background-color: black; */
        /* z-index: 1; */
        height: auto;
        width: 32%;
        border: 3px solid #494A7E;
        border-radius: 20px;
    }
    
    .header_card .header_card_icon img {
        height: 68px;
        width: 68px;
    }

    .bargained_icon {
        height: 76px!important;
        width: 76px!important;
        /* margin-top: 30px; */
    }

    .money_icon {
        height: 80px!important;
        width: 80px!important;
    }
    
    .secure_icon {
        height: 58px!important;
        width: 58px!important;
        margin-top: 0px;
    }
    
    .header_card .header_card_icon {
        width: 110px;
        height: 110px;
        background-color: var(--white);
        box-shadow: 0px 2px 0px rgba(47, 49, 96, 0.2);
        -webkit-transform: translateX(24px);
                transform: translateX(24px);
        /* position: absolute; */
        /* top: 105px; */
        z-index: 1;
        border-radius: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4%;
    }
    
    
    .header_card_content .header_card_heading {
        color: var(--yellow);
        margin-bottom: 10px!important;
        font-weight: 100;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0px;
    }
    
    .header_card_content .header_card_para {
        color: var(--white);
        font-weight: 100; 
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
}

@media (max-width: 600px) {
    /* .cabtm_container {
        height: 420px!important;
    } */

    .header_card_content {
        background: none;
        /* position: relative; */
        /* top: 30px; */
        height: 320px;
        border-radius: 20px;
        padding: 5% 10% 5% 10%;
        
    }
    
    .header_card {
        padding-top: 1.8%;
        /* position: relative; */
        /* bottom: 3.5rem; */
        /* height: 16rem; */
        /* background-color: black; */
        /* z-index: 1; */
        height: auto;
        width: 100%;
        margin-bottom: 20px;
    }

    .header_card:last-child {
        margin-bottom: 0px;
    }
    
    .header_card .header_card_icon img {
        height: 70px;
        width: 70px;
    }

    .bargained_icon {
        height: 58px!important;
        width: 58px!important;
        /* margin-top: 20px; */
    }
    
    .secure_icon {
        height: 56px!important;
        width: 56px!important;
        margin-top: 0px;
    }

    #cabtainservice .header_card_content {
        height: 240px;
    }
    
    .header_card .header_card_icon {
        width: 100px;
        height: 100px;
        background-color: var(--white);
        box-shadow: 0px 2px 0px var(--lightblue);
        -webkit-transform: translateX(24px);
                transform: translateX(24px);
        /* position: relative; */
        /* top: 80px; */
        z-index: 1;
        border-radius: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8%;
    }
    
    
    .header_card_content .header_card_heading {
        color: var(--yellow);
        margin-bottom: 10px!important;
        font-weight: 100;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 1px;
    }
    
    .header_card_content .header_card_para {
        color: var(--white);
        font-weight: 100; 
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.8px;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    /* .cabtm_container {
        height: 420px!important;
    } */

    .header_card_content {
        background: none;
        position: relative;
        top: 30px;
        height: auto;
        padding: 15% 8% 2% 8%;
        
    }
    
    .header_card {
        padding-top: 1.8%;
        position: relative;
        bottom: 3.5rem;
        /* height: 16rem; */
        /* background-color: black; */
        /* z-index: 1; */
        height: auto;
        width: 75%;
    }
    
    .header_card .header_card_icon img {
        height: 60px!important;
        width: 60px!important;
    }

    .bargained_icon {
        height: 62px!important;
        width: 62px!important;
        margin-top: 20px;
    }
    
    .secure_icon {
        height: 60px!important;
        width: 60px!important;
        margin-top: 0px;
    }
    
    .header_card .header_card_icon {
        width: 120px;
        height: 120px;
        background-color: var(--white);
        box-shadow: 0px 4px 0px var(--lightblue);
        -webkit-transform: translateX(24px);
                transform: translateX(24px);
        position: relative;
        top: 80px;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    
    .header_card_content .header_card_heading {
        color: var(--yellow);
        margin-bottom: 10px!important;
        font-weight: 400;
        font-size: 24px;
        line-height: 26px;
        letter-spacing: 1px;
    }
    
    .header_card_content .header_card_para {
        color: var(--white);
        font-weight: 400; 
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.7px;
    }
}
.slider {
    display: flex;
    justify-content: space-between;
}

.slider .slider_card {
    background: none;
    border-radius: 20px;
    width: 54%;
    height: auto;
    padding: 0;
    box-sizing: border-box;
    border-radius: 15px;
}

.slider .slider_card .slider_card_heading {
    margin-bottom: 16px;
    color: var(--yellow);
    font-weight: 300;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: 1px;
}

.slider .slider_card .slider_card_para {
    color: var(--white);
    font-weight: 200;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 2px;
}

.slider .slider_img {
    width: 350px;
    height: 673px;
    position: absolute;
    right: 25%;
    /* top: -34%; */
    -webkit-transform: translateY(-67%);
            transform: translateY(-67%);
}

.slider .slider_img img {
    width: 100%;
    height: 100%;
}

.slider .slider_btn_container {
    display: flex;
    justify-content: space-between;
    height: 47px;
    width: 120px;
    position: absolute;
    right: 18%;
    top: 12rem;
}

.slider .slider_btn_container button {
    background-color: var(--white);
    border: none;
    border-radius: 30px;
    padding: 10px 10px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    width: 48px;
    height: 48px;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .slider {
        display: flex;
        justify-content: space-between;
    }
    
    .slider .slider_card {
        background: none;
        border-radius: 20px;
        width: 48%;
        height: auto;
        padding: 2.5% 4%;
    }
    
    .slider .slider_card .slider_card_heading {
        margin-bottom: 16px;
        color: var(--yellow);
        font-weight: 300;
        font-size: 22px;
        line-height: 27px;
        letter-spacing: 0.3px;
    }
    
    .slider .slider_card .slider_card_para {
        color: var(--white);
        font-weight: 100;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
        letter-spacing: 0.5px;
    }
    
    .slider .slider_img {
        width: 250px;
        height: 485px;
        position: absolute;
        right: 27.5%;
        /* top: -34%; */
        -webkit-transform: translateY(-60%);
                transform: translateY(-60%);
    }
    
    .slider .slider_img img {
        width: 100%;
        height: 100%;
    }
    
    .slider .slider_btn_container {
        display: flex;
        justify-content: space-between;
        height: 47px;
        width: 100px;
        position: absolute;
        right: 18%;
        top: 25%
    }
    
    .slider .slider_btn_container button {
        background-color: var(--white);
        border: none;
        border-radius: 30px;
        padding: 10px 10px;
        /* display: flex;
        justify-content: center;
        align-items: center; */
        width: 40px;
        height: 40px;
    }

    .slider .slider_btn_container button img {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 600px) {
    .slider {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .slider .slider_card {
        background: none;
        border-radius: 20px;
        width: 100%;
        height: auto;
        padding: 6.5% 8%;
        margin-bottom: 30px;
    }
    
    .slider .slider_card .slider_card_heading {
        margin-bottom: 16px;
        color: var(--yellow);
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 1px;
    }
    
    .slider .slider_card .slider_card_para {
        color: var(--white);
        font-weight: 200;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0px;
        letter-spacing: 1.5px;
    }
    
    .slider .slider_img {
        width: 180px;
        height: auto;
        position: static;
        /* right: 27.5%; */
        /* top: -34%; */
        -webkit-transform: translateY(-5%);
                transform: translateY(-5%);
        -webkit-transform: translateX(35%);
                transform: translateX(35%);
    }
    
    .slider .slider_img img {
        width: 100%;
        height: 100%;
    }
    
    .slider .slider_btn_container {
        display: flex;
        justify-content: space-between;
        height: 47px;
        width: 100px;
        position: static;
        margin-top: 15px;
        /* right: 18%;
        top: 25% */
        margin-left: 10px;
        -webkit-transform: translateX(90%);
                transform: translateX(90%);
    }
    
    .slider .slider_btn_container button {
        background-color: var(--white);
        border: none;
        border-radius: 30px;
        padding: 10px 10px;
        /* display: flex;
        justify-content: center;
        align-items: center; */
        width: 40px;
        height: 40px;
    }

    .slider .slider_btn_container button img {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .slider {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .slider .slider_card {
        background: none;
        width: 100%;
        height: auto;
        padding: 5% 7%;
        margin-bottom: 30px;
    }
    
    .slider .slider_card .slider_card_heading {
        margin-bottom: 16px;
        color: #040726;
        font-weight: 500;
        font-size: 28px;
        line-height: 26px;
        letter-spacing: 1px;
    }
    
    .slider .slider_card .slider_card_para {
        color: #040726;
        font-weight: 200;
        font-size: 26px;
        line-height: 28px;
        margin-bottom: 0px;
        letter-spacing: 0.5px;
    }
    
    .slider .slider_img {
        width: 200px;
        height: 348px;
        position: static;
        /* right: 27.5%; */
        /* top: -34%; */
        -webkit-transform: translateY(-5%);
                transform: translateY(-5%);
    }
    
    .slider .slider_img img {
        width: 100%;
        height: 100%;
    }
    
    .slider .slider_btn_container {
        display: flex;
        justify-content: space-between;
        height: 47px;
        width: 140px;
        position: static;
        margin-top: 5px;
        /* right: 18%;
        top: 25% */
        margin-left: 10px;
    }
    
    .slider .slider_btn_container button {
        background-color: var(--white);
        border: none;
        padding: 10%;
        /* display: flex;
        justify-content: center;
        align-items: center; */
        width: 50px;
        height: 50px;
    }

    .slider .slider_btn_container button img {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
/* .slider_section {
    
} */

.slider_section .slider_background {
    width: 100%;
}

.slider_section .slider_img_text {
    position: absolute;
    /* bottom: 74.5%; */
    left: 18%;
    -webkit-transform: translateY(-20rem);
            transform: translateY(-20rem);
    color: var(--white);
    line-height: 45px;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
}

.slider_section .slider_img_text p {
    font-size: 60px;
    line-height: 60px;
    width: 60%;
    margin-bottom: 10px;
    letter-spacing: 2px;
}

.slider_section .slider_img_text span {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 1px;
    color: #0D8944;
}

.slider_section .cabtm_slider_container {
    -webkit-transform: translateY(-70px);
            transform: translateY(-70px);
    height: auto;
}

.slider_section .cabtm_slider_content {
    width: 62.5%;
    padding: 6% 0;
    margin-bottom: 20px;
}

.slider_section .cabtm_slider_content .cabtm_slider_card_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 130px;
}

.slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card {
    background: none;
    border-radius: 20px;
    width: 48%;
    height: auto;
    padding: 3% 5.5%;
    /* border: 3px solid #494A7E; */
}

.slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_heading {
    color: #0D8944;
    font-weight: 100;
    font-size: 52px;
    line-height: 64px;
    margin-bottom: 16px;
    width: 120%;
}

.slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_para {
    font-size: 24px;
    line-height: 36px;
    color: #E2E2E2;
    width: 140%;
    letter-spacing: 1px;
    font-weight: 200;
    line-height: 36px;
}

.slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img {
    border-radius: 40px;
    width: 40%;
    height: auto;
}

#earnwithcabtm .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img {
    height: 540px;
}

.slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .slider_section .slider_background {
        width: 100%;
    }
    
    .slider_section .slider_img_text {
        position: absolute;
        /* bottom: 70.5%; */
        left: 20%;
        -webkit-transform: translateY(-16rem);
                transform: translateY(-16rem);
        color: var(--white);
        line-height: 45px;
        -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
    }
    
    .slider_section .slider_img_text p {
        font-size: 42px;
        line-height: 42px;
        width: 60%;
        margin-bottom: 0px;
        letter-spacing: 1px;
    }
    
    .slider_section .slider_img_text span {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 1px;
        color: #0D8944;
    }
    
    .slider_section .cabtm_slider_content {
        width: 62.5%;
        padding: 6% 0;
        margin-bottom: 20px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card {
        background: none;
        border-radius: 20px;
        width: 48%;
        height: 280px;
        padding: 3% 5.5%;
        /* border: 3px solid #494A7E; */
    }

    #earnwithcabtm .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card {
        background: none;
        border-radius: 20px;
        width: 48%;
        height: 440px;
        padding: 3% 5.5%;
        /* border: 3px solid #494A7E; */
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_heading {
        color: #0D8944;
        font-weight: 400;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 14px;
        letter-spacing: 1px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_para {
        font-size: 18px;
        line-height: 26px;
        color: #E2E2E2;
        margin-bottom: 0!important;
        width: 120%;
        letter-spacing: 1px;
        font-weight: 200;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img {
        border-radius: 20px;
        width: 40%;
        height: auto;
    }

    #earnwithcabtm .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img {
        background: var(--white);
        box-shadow: 0px 2px 0px var(--lightblue);
        border-radius: 20px;
        width: 48%;
        height: 440px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 20px;
    }
}


@media (max-width: 600px) {
    .slider_section .slider_background {
        width: 100%;
        height: 14rem;
        object-fit: cover;
    }
    
    .slider_section .slider_img_text {
        position: absolute;
        /* bottom: 70.5%; */
        left: 10%;
        -webkit-transform: translateY(-10rem);
                transform: translateY(-10rem);
        color: var(--white);
        line-height: 45px;
        -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
    }
    
    .slider_section .slider_img_text p {
        font-size: 30px;
        line-height: 30px;
        width: 70%;
        margin-bottom: 0px;
        letter-spacing: 1px;
    }
    
    .slider_section .slider_img_text span {
        font-size: 14px;
        line-height: 2px;
        letter-spacing: 1px;
    }

    .slider_section .cabtm_slider_container {
        -webkit-transform: translateY(-18px);
                transform: translateY(-18px);
        height: auto;
    }
    
    .slider_section .cabtm_slider_content {
        width: 85%;
        padding: 0 0;
        margin-bottom: 20px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card {
        background: none;
        border-radius: 20px;
        width: 94%;
        height: auto;
        padding: 0!important;
        margin-bottom: 40px;
        text-align: left;
        /* border: 3px solid #494A7E; */
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_heading {
        color: #0D8944;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 16px;
        width: 100%;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_para {
        font-size: 16px;
        line-height: 22px;
        color: var(--lightgrey);
        margin-bottom: 0!important;
        font-weight: 200;
        width: 100%;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img {
        border-radius: 20px;
        width: 90%;
        height: auto;
        margin-bottom: 40px;
        
    }

    #earnwithcabtm .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img {
        height: 180px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 20px;
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .slider_section .slider_background {
        width: 100%;
        height: 18rem;
        object-fit: cover;
    }
    
    .slider_section .slider_img_text {
        position: absolute;
        /* bottom: 70.5%; */
        left: 15%;
        -webkit-transform: translateY(-12rem);
                transform: translateY(-12rem);
        color: var(--white);
        line-height: 45px;
        -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
    }
    
    .slider_section .slider_img_text p {
        font-size: 38px;
        line-height: 38px;
        width: 60%;
        margin-bottom: 10px;
        letter-spacing: 2px;
    }
    
    .slider_section .slider_img_text span {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 1px;
    }

    .slider_section .cabtm_slider_container {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
        height: auto;
    }
    
    .slider_section .cabtm_slider_content {
        width: 73%;
        padding: 15% 0;
        margin-bottom: 20px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 40px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card {
        background: var(--white);
        box-shadow: 0px 4px 0px var(--lightblue);
        width: 100%;
        height: auto;
        padding: 5.5% 8%;
        margin-bottom: 40px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_heading {
        color: var(--darkblue);
        font-weight: 500;
        font-size: 30px;
        line-height: 28px;
        margin-bottom: 16px;
        letter-spacing: 1px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_para {
        font-size: 20px;
        line-height: 26px;
        color: var(--lightgrey);
        letter-spacing: 1px;
        font-weight: 200;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img {
        background: var(--white);
        box-shadow: 0px 4px 0px var(--lightblue);
        width: 100%;
        height: 310px;
        margin-bottom: 40px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
/* .footer {
    transform: translateY(0rem);
}

.App {
    height: 0vh;
} */

#cabtainservice {
    height: 490.5vh;
}

.cabtm_container {
    display: flex;
    justify-content: center;
    height: auto;
    align-items: center;
}

.faq_container {
    background-color: var(--darkblue);
    -webkit-transform: translateY(-106px);
            transform: translateY(-106px);
    padding-top: 90px;
    padding-bottom: 00px;
}

.faq_container .faq_container_title {
    text-align: center; 
    color: var(--white); 
    margin-bottom: 30px; 
    font-size: 40px; 
    line-height: 4; 
    letter-spacing: 0.5px;
}

.faq_container .footer {
    margin-top: 7%;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .faq_container {
        background-color: var(--darkblue);
        -webkit-transform: translateY(-106px);
                transform: translateY(-106px);
        padding-top: 30px;
        padding-bottom: 0px;
    }

    #cabtainservice .header_card_content {
        height: 220px;
    }

    #cabtainservice .header_card {
        height: 330px;
    }
}

@media (max-width: 600px) {
    .faq_container .faq_container_title {
        text-align: center; 
        color: var(--white); 
        margin-bottom: 32px; 
        font-size: 20px!important; 
        line-height: 1!important; 
        letter-spacing: 0.5px;
    }

    .faq_container {
        padding-bottom: 30px;
    }
}
.cabtm_container {
    display: flex;
    justify-content: center;
    height: auto;
    align-items: center;
}

.faq_container {
    background-color: var(--darkblue);
    -webkit-transform: translateY(-106px);
            transform: translateY(-106px);
    padding-top: 90px;
    padding-bottom: 0px;
}

.faq_container .faq_container_title {
    text-align: center; 
    color: var(--white); 
    margin-bottom: 30px; 
    font-size: 40px; 
    line-height: 4; 
    letter-spacing: 0px;
}

#earnwithcabtain {
    min-height: 405vh;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .faq_container {
        background-color: var(--darkblue);
        -webkit-transform: translateY(-106px);
                transform: translateY(-106px);
        padding-top: 30px;
        padding-bottom: 0px;
    }
}

@media (max-width: 600px) {
    .faq_container .faq_container_title {
        text-align: center; 
        color: var(--white); 
        margin-bottom: 32px; 
        font-size: 20px!important; 
        line-height: 1!important; 
        letter-spacing: 1px;
    }

    .cabtm_container {
        display: flex;
        justify-content: center;
        height: auto;
        align-items: center;
        padding: 15% 0;
    }
}
#faq_container {
    min-height: 220vh;
}

.faq_cabtm_container {
    padding: 6.5% 0 10% 0;
    display: flex;
    justify-content: center;
}

@media (max-width: 600px) {
    .faq_cabtm_container {
        padding: 20% 0 20% 0;
        display: flex;
        justify-content: center;
    }

    .header_content {
        display: flex;
        justify-content: space-between;
        width: 73%;
        padding: 15% 0;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .faq_cabtm_container {
        padding: 20% 0 20% 0;
        display: flex;
        justify-content: center;
    }

    .header_content {
        display: flex;
        justify-content: space-between;
        width: 73%;
        /* padding: 15% 0; */
    }
}
.contact_cabtm_container {
    padding: 1.5% 0 1.5% 0;
    display: flex;
    justify-content: center;
}

.contact_map_container {
    background-image:url(../../static/media/contact_map.18311aad.png);
    background-color: rgba(226, 226, 226, 0.9);
    background-blend-mode: lighten;
    background-size: 100% 100%;
    width: 62.5%;
    height: 325px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact_map_container span {
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: #069F03;
    cursor: pointer;
}

.contact_item {
    margin-bottom: 20px;
}

.contact_item .contact_item_heading {
    color: var(--yellow);
    font-size: 30px;
    line-height: 32px;
    letter-spacing: 2px;
}

.contact_item .contact_item_para {
    color: var(--white);
    font-weight: 300;
    font-size: 24px;
    line-height: 34px;
}

.contact_item .contact_item_para a {
    color: var(--white);
}

.contact_container {
    height: 110vw;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1){
    .contact_map_container {
        background-image:url(../../static/media/contact_map.18311aad.png);
        background-color: rgba(226, 226, 226, 0.9);
        background-blend-mode: lighten;
        background-size: 100% 100%;
        width: 62.5%;
        height: 240px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contact_cabtm_container .cabtm_container_content {
        align-items: flex-start;
    }

    .contact_cabtm_container .cabtm_container_content .contact_content_container {
        margin-top: 10px;
    }
    
    .contact_map_container span {
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #069F03;
    }
    
    .contact_item {
        margin-bottom: 20px;
    }
    
    .contact_item .contact_item_heading {
        color: var(--yellow);
        font-size: 22px;
        line-height: 10px;
        letter-spacing: 1px;
    }
    
    .contact_item .contact_item_para {
        color: var(--white);
        font-weight: 300;
        font-size: 15px;
        line-height: 26px;
        letter-spacing: 0.5px;
    }
}

@media (max-width: 600px) {
    .contact_cabtm_container {
        padding: 20% 0 20% 0;
    }

    .contact_map_container {
        background-image:url(../../static/media/contact_map.18311aad.png);
        background-color: rgba(226, 226, 226, 0.9);
        background-blend-mode: lighten;
        background-size: 100% 100%;
        width: 100%;
        height: 140px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
    }
    
    .contact_map_container span {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #069F03;
    }
    
    .contact_item {
        margin-bottom: 20px;
    }
    
    .contact_item .contact_item_heading {
        color: var(--yellow);
        font-size: 18px;
        line-height: 10px;
        letter-spacing: 1px;
    }
    
    .contact_item .contact_item_para {
        color: var(--white);
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
    }
    
    .contact_content_container {
        width: 100%!important;
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .contact_cabtm_container {
        padding: 20% 0 20% 0;
        justify-content: center;
    }

    .contact_map_container {
        background-image:url(../../static/media/contact_map.18311aad.png);
        background-color: rgba(226, 226, 226, 0.9);
        background-blend-mode: lighten;
        width: 70%;
        height: 140px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .contact_map_container span {
        font-weight: 500;
        font-size: 24px;
        line-height: 20px;
        color: #069F03;
        text-decoration: underline;
    }
    
    .contact_item {
        margin-bottom: 20px;
    }
    
    .contact_item .contact_item_heading {
        color: var(--yellow);
        font-size: 28px;
        line-height: 10px;
        letter-spacing: 2px;
    }
    
    .contact_item .contact_item_para {
        color: var(--white);
        font-weight: 300;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: 1px;
    }
    
    .contact_content_container {
        width: 100%!important;
        flex-direction: column;
    }
}
.terms_link {
    padding: 5% 0;
    border-bottom: 0.75px solid rgba(73, 74, 126, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.terms_link p {
    color: var(--white);
    margin-bottom: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
}

.terms_link .active {
    color: var(--yellow);
}

.terms_link img {
    width: 18px;
    height: 18px;
}

.terms_content_info {
    font-size: 24px;
    line-height: 20px;
    color: var(--yellow);
    margin-bottom: 40px;
}

#terms_container .cabtm_privacy_content {
    flex-direction: row;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .terms_link {
        padding: 5% 0;
        border-bottom: 0.75px solid rgba(73, 74, 126, 0.5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    
    .terms_link p {
        color: var(--white);
        margin-bottom: 0;
        font-weight: 500;
        font-size: 17px;
        line-height: 18px;
    }
    
    .terms_link .active {
        color: var(--yellow);
    }
    
    .terms_link img {
        width: 16px;
        height: 16px;
    }
    
    .terms_content_info {
        font-size: 20px;
        line-height: 18px;
        color: var(--yellow);
        margin-bottom: 30px;
    }
}

@media (max-width: 600px) {
    .terms_link {
        padding: 5% 0;
        border-bottom: 0.75px solid rgba(73, 74, 126, 0.5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    
    .terms_link p {
        color: var(--white);
        margin-bottom: 0;
        font-weight: 500;
        font-size: 17px;
        line-height: 18px;
    }
    
    .terms_link .active {
        color: var(--yellow);
    }

    .cabtm_privacy_content_link {
        position: -webkit-sticky;
        position: sticky;
    }
    
    .terms_link img {
        width: 16px;
        height: 16px;
    }
    
    .terms_content_info {
        font-size: 20px;
        line-height: 22px;
        color: var(--yellow);
        margin-bottom: 30px;
    }

    .cabtm_privacy_content_link {
        width: 100%!important;
    }

    .cabtm_privacy_content_content {
        width: 100%!important;
    }

    .sticky {
        position: fixed;
        top: 0;
        width: 100%;
    }

    #terms_container .cabtm_privacy_content {
        flex-direction: column;
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .terms_link {
        padding: 5% 0;
        border-bottom: 0.75px solid rgba(73, 74, 126, 0.5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    
    .terms_link p {
        color: var(--white);
        margin-bottom: 0;
        font-weight: 500;
        font-size: 26px;
        line-height: 18px;
        letter-spacing: 2px;
    }
    
    .terms_link .active {
        color: var(--yellow);
    }

    .cabtm_privacy_content_link {
        position: -webkit-sticky;
        position: sticky;
    }
    
    .terms_link img {
        width: 22px;
        height: 22px;
    }
    
    .terms_content_info {
        font-size: 28px;
        line-height: 28px;
        color: var(--yellow);
        margin-bottom: 30px;
        letter-spacing: 1px;
    }

    .cabtm_privacy_content_link {
        width: 100%!important;
    }

    .cabtm_privacy_content_content {
        width: 100%!important;
    }

    /* .sticky {
        position: fixed;
        top: 0;
        width: 70%!important;
      } */

    #terms_container .cabtm_privacy_content {
        flex-direction: column;
    }
}
.privacy_policy_info p {
    color: var(--yellow);
    width: 997px;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px;
}

.privacy_policy_container {
    margin: 3% 0;
    width: 85%;
}

.privacy_policy_container .privacy_policy_heading {
    color: var(--white);
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    width: 46%;
    letter-spacing: 2px;
}

.privacy_policy_container .privacy_policy_para {
    color: #E2E2E2;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 2px;
}

.cabtm_privacy_content {
    width: 63%;
    display: flex;
    flex-direction: column;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .privacy_policy_info p {
        color: var(--yellow);
        width: 745px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0px;
    }
    
    .privacy_policy_container {
        margin: 3% 0;
        width: 85%;
    }
    
    .privacy_policy_container .privacy_policy_heading {
        color: var(--white);
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        width: 50%;
        letter-spacing: 0px;
    }
    
    .privacy_policy_container .privacy_policy_para {
        color: #E2E2E2;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 1px;
    }
}

@media (max-width: 600px) {
    .privacy_policy_info p {
        color: var(--yellow);
        width: 100%;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0px;
    }
    
    .privacy_policy_container {
        margin: 3% 0;
        width: 85%;
    }
    
    .privacy_policy_container .privacy_policy_heading {
        color: var(--white);
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        letter-spacing: 0px;
    }
    
    .privacy_policy_container .privacy_policy_para {
        color: #E2E2E2;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
        width: 100%;
    }

    .cabtm_privacy_content {
        width: 80%;
        flex-direction: column;
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .privacy_policy_info p {
        color: var(--yellow);
        width: 100%;
        font-size: 26px;
        line-height: 36px;
        letter-spacing: 0px;
    }
    
    .privacy_policy_container {
        margin: 3% 0;
        width: 90%;
    }
    
    .privacy_policy_container .privacy_policy_heading {
        color: var(--white);
        font-weight: 500;
        font-size: 26px;
        line-height: 36px;
        width: 100%;
        letter-spacing: 2px;
    }
    
    .privacy_policy_container .privacy_policy_para {
        color: #E2E2E2;
        font-size: 21px;
        line-height: 26px;
        letter-spacing: 1.8px;
        width: 100%;
    }

    .cabtm_privacy_content {
        width: 80%;
        flex-direction: column;
    }
}
