.about {
    background-image: url('./pattern.svg');
    padding: 5.8% 0;
    height: 988px!important;
}

.about_text {
    width: 62.5%;
}

.about_heading {
    font-size: 30px;
    letter-spacing: 2px;
    color: var(--yellow);
    font-weight: 300;
}


.about_main {
    font-size: 40px;
    letter-spacing: 2px;
    color: var(--white);
    width: 80%;
    line-height: 50px;
    font-weight: 100;
}

.about_sub {
    color: var(--lightgrey);
    width: 82%;
    font-size: 1.5rem;
    font-weight: 100;
}

.about_items_container {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}

.about_items_container .about_item  {
    width: 50%;
}

.about_items_container .about_item  .about_item_heading {
    color: #069F03;
    text-align: left;
    font-weight: 100;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: 2px;
}

.about_items_container .about_item  .about_item_para {
    color: var(--lightgrey);
    width: 90%;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    /* line-height: 36px; */
    /* letter-spacing: 2px; */
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .about {
        background-image: url('./pattern.svg');
        padding: 5.8% 0;
        height: 830px!important;
    }
    
    .about_text {
        width: 62.5%;
    }
    
    .about_heading {
        font-size: 1.35rem;
        color: var(--yellow);
        font-weight: 300;
        letter-spacing: 1px;
    }
    
    
    .about_main {
        font-size: 1.76rem;
        color: var(--white);
        line-height: 36px;
        /* width: 95%; */
        letter-spacing: 1px;
    }
    
    .about_sub {
        color: var(--lightgrey);
        width: 82%;
        font-size: 1.05rem;
        letter-spacing: 1px;
    }
    
    .about_items_container {
        display: flex;
        justify-content: space-between;
    }
    
    .about_items_container .about_item  {
        width: 50%;
    }
    
    .about_items_container .about_item  .about_item_heading {

        color: #069F03;
        text-align: left;
        font-size: 1.4rem;
        letter-spacing: 1px;
    }
    
    .about_items_container .about_item  .about_item_para {
        color: var(--lightgrey);
        width: 90%;
        font-size: 1.05rem;
        letter-spacing: 1px;
    }
}

@media (max-width: 600px) {
    .about {
        background-image: url('./pattern.svg');
        padding: 10% 0;
        height: auto!important;
    }
    
    .about_text {
        /* margin: 30% 0; */
        width: 85%;
    }
    
    .about_heading {
        font-size: 1rem;
        color: var(--yellow);
        font-weight: 300;
        letter-spacing: 1px;
    }
    
    
    .about_main {
        font-size: 1.5rem;
        color: var(--white);
        line-height: 32px;
        /* width: 95%; */
        letter-spacing: 1px;
        width: 100%;
    }
    
    .about_sub {
        color: var(--lightgrey);
        /* width: 90%; */
        font-size: 1rem;
        line-height: 24px;
        letter-spacing: 0.5px;
        width: 100%;
    }
    
    .about_items_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 10px;
    }
    
    .about_items_container .about_item  {
        width: 100%;
    }
    
    .about_items_container .about_item  .about_item_heading {

        color: #069F03;
        text-align: left;
        font-size: 1.3rem;
        letter-spacing: 1px;
        margin-bottom: 8px;
        width: 100%;
    }
    
    .about_items_container .about_item  .about_item_para {
        color: var(--lightgrey);
        /* width: 90%; */
        font-size: 1rem;
        line-height: 24px;
        letter-spacing: 0.5px;
        width: 100%;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .about {
        background-image: url('./pattern.svg');
        padding: 20% 0;
        height: 1100px!important;
    }
    
    .about_text {
        /* margin: 30% 0; */
        width: 80%;
    }
    
    .about_heading {
        font-size: 1.4rem;
        color: var(--yellow);
        font-weight: 100;
        letter-spacing: 1px;
    }
    
    
    .about_main {
        font-size: 1.5rem;
        color: var(--white);
        line-height: 30px;
        /* width: 95%; */
        letter-spacing: 1px;
        width: 100%;
    }
    
    .about_sub {
        color: var(--lightgrey);
        /* width: 82%; */
        font-size: 1.1rem;
        letter-spacing: 1px;
        margin-right: 1px;
        width: 100%;
        line-height: 28px;
    }
    
    .about_items_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 10px;
    }
    
    .about_items_container .about_item  {
        width: 100%;
    }
    
    .about_items_container .about_item  .about_item_heading {

        color: #069F03;
        text-align: left;
        font-size: 1.4rem;
        letter-spacing: 1px;
        margin-bottom: 8px;
        width: 100%;
    }
    
    .about_items_container .about_item  .about_item_para {
        color: var(--lightgrey);
        /* width: 90%; */
        font-size: 1.1rem;
        line-height: 28px;
        letter-spacing: 0.5px;
        width: 100%;
    }
}