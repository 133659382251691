.contact_cabtm_container {
    padding: 1.5% 0 1.5% 0;
    display: flex;
    justify-content: center;
}

.contact_map_container {
    background-image:url('./contact_map.png');
    background-color: rgba(226, 226, 226, 0.9);
    background-blend-mode: lighten;
    background-size: 100% 100%;
    width: 62.5%;
    height: 325px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact_map_container span {
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: #069F03;
    cursor: pointer;
}

.contact_item {
    margin-bottom: 20px;
}

.contact_item .contact_item_heading {
    color: var(--yellow);
    font-size: 30px;
    line-height: 32px;
    letter-spacing: 2px;
}

.contact_item .contact_item_para {
    color: var(--white);
    font-weight: 300;
    font-size: 24px;
    line-height: 34px;
}

.contact_item .contact_item_para a {
    color: var(--white);
}

.contact_container {
    height: 110vw;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1){
    .contact_map_container {
        background-image:url('./contact_map.png');
        background-color: rgba(226, 226, 226, 0.9);
        background-blend-mode: lighten;
        background-size: 100% 100%;
        width: 62.5%;
        height: 240px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contact_cabtm_container .cabtm_container_content {
        align-items: flex-start;
    }

    .contact_cabtm_container .cabtm_container_content .contact_content_container {
        margin-top: 10px;
    }
    
    .contact_map_container span {
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #069F03;
    }
    
    .contact_item {
        margin-bottom: 20px;
    }
    
    .contact_item .contact_item_heading {
        color: var(--yellow);
        font-size: 22px;
        line-height: 10px;
        letter-spacing: 1px;
    }
    
    .contact_item .contact_item_para {
        color: var(--white);
        font-weight: 300;
        font-size: 15px;
        line-height: 26px;
        letter-spacing: 0.5px;
    }
}

@media (max-width: 600px) {
    .contact_cabtm_container {
        padding: 20% 0 20% 0;
    }

    .contact_map_container {
        background-image:url('./contact_map.png');
        background-color: rgba(226, 226, 226, 0.9);
        background-blend-mode: lighten;
        background-size: 100% 100%;
        width: 100%;
        height: 140px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
    }
    
    .contact_map_container span {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #069F03;
    }
    
    .contact_item {
        margin-bottom: 20px;
    }
    
    .contact_item .contact_item_heading {
        color: var(--yellow);
        font-size: 18px;
        line-height: 10px;
        letter-spacing: 1px;
    }
    
    .contact_item .contact_item_para {
        color: var(--white);
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
    }
    
    .contact_content_container {
        width: 100%!important;
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .contact_cabtm_container {
        padding: 20% 0 20% 0;
        justify-content: center;
    }

    .contact_map_container {
        background-image:url('./contact_map.png');
        background-color: rgba(226, 226, 226, 0.9);
        background-blend-mode: lighten;
        width: 70%;
        height: 140px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .contact_map_container span {
        font-weight: 500;
        font-size: 24px;
        line-height: 20px;
        color: #069F03;
        text-decoration: underline;
    }
    
    .contact_item {
        margin-bottom: 20px;
    }
    
    .contact_item .contact_item_heading {
        color: var(--yellow);
        font-size: 28px;
        line-height: 10px;
        letter-spacing: 2px;
    }
    
    .contact_item .contact_item_para {
        color: var(--white);
        font-weight: 300;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: 1px;
    }
    
    .contact_content_container {
        width: 100%!important;
        flex-direction: column;
    }
}