.faq_question_container {
    border-top: 1px solid rgba(73, 74, 126, 0.5);
    width: 100%;
    padding: 2.5% 0 1.8% 0;
    /* background-color: #fff; */
}

.faq_question_container:last-child {
    border-bottom: 1px solid rgba(73, 74, 126, 0.5);
}

.faq_question_container .faq_question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.faq_question_container .faq_question img {
    width: 32px;
    height: 20px;
}

.faq_question_container .faq_question p {
    font-weight: 300;
    color: var(--white);
    margin-bottom: 0!important;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 1px;
}


.faq_question_container .active p {
    color: var(--yellow);
}

.faq_question_container .faq_answer {
    color: #E2E2E2;
    margin-left: 5.5%;
    margin-right: 20%;
    margin-top: 20px;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 2px;
    font-weight: 100;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .faq_question_container {
        border-top: 1px solid rgba(73, 74, 126, 0.5);
        
        width: 100%;
        padding: 2.5% 0 1.8% 0;
        /* background-color: #fff; */
    }
    
    .faq_question_container:last-child {
        border-bottom: 1px solid rgba(73, 74, 126, 0.5);
    }

    .faq_question_container .faq_question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    
    .faq_question_container .faq_question img {
        width: 28px;
        height: 16px;
    }
    
    .faq_question_container .faq_question p {
        font-weight: 300;
        color: var(--white);
        margin-bottom: 0!important;
        font-size: 22px;
        line-height: 40px;
        letter-spacing: 0.5px;
    }
    
    
    .faq_question_container .active p {
        color: var(--yellow);
    }
    
    .faq_question_container .faq_answer {
        color: #E2E2E2;
        margin-left: 5.5%;
        margin-right: 20%;
        margin-top: 20px;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 1px;
    }

    .faq_question_container .faq_answer p {
        margin-bottom: 0!important
    }
}

@media (max-width: 600px) {
    .faq_question_container {
        border-top: 1px solid rgba(73, 74, 126, 0.5);
        width: 100%;
        padding: 5.5% 0 5.8% 0;
        /* background-color: #fff; */
    }

    .faq_question_container:last-child {
        border-bottom: 1px solid rgba(73, 74, 126, 0.5);
    }
    
    .faq_question_container .faq_question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    
    .faq_question_container .faq_question img {
        width: 24px;
        height: 12px;
        margin-left: auto;
    }

    .faq_question_container .faq_question .arrow_right_faq img {
        width: 12px!important;
        height: 12px!important;
    }

    .faq_question_container .faq_question .arrow_right_faq {
        width: 6%!important;
    }
    
    .faq_question_container .faq_question p {
        font-weight: 300;
        color: var(--white);
        margin-bottom: 0!important;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        margin-left: 20px;
        font-size: 100;
        width: 90%;
    }
    
    
    .faq_question_container .active p {
        color: var(--yellow);
        font-size: 16px;
    }

    .faq_question_container .faq_answer {
        margin-right: 2%;
    }
    
    .faq_question_container .faq_answer p {
        color: #E2E2E2;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 20px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1.5px;
        font-weight: 100;
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .faq_question_container {
        border-top: 2px solid rgba(73, 74, 126, 0.5);
        border-bottom: 1px solid rgba(73, 74, 126, 0.5);
        width: 100%;
        padding: 2.5% 0 1.8% 0;
        /* background-color: #fff; */
    }
    
    .faq_question_container .faq_question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    
    .faq_question_container .faq_question img {
        width: 28px;
        height: 16px;
    }
    
    .faq_question_container .faq_question p {
        font-weight: 300;
        color: var(--white);
        margin-bottom: 0!important;
        font-size: 22px;
        line-height: 40px;
        letter-spacing: 0.5px;
    }
    
    
    .faq_question_container .active p {
        color: var(--yellow);
    }
    
    .faq_question_container .faq_answer p {
        color: var(--grey);
        margin-left: 5.5%;
        margin-right: 20%;
        margin-top: 20px;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.5px;
    }
}