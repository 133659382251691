.slider {
    display: flex;
    justify-content: space-between;
}

.slider .slider_card {
    background: none;
    border-radius: 20px;
    width: 54%;
    height: auto;
    padding: 0;
    box-sizing: border-box;
    border-radius: 15px;
}

.slider .slider_card .slider_card_heading {
    margin-bottom: 16px;
    color: var(--yellow);
    font-weight: 300;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: 1px;
}

.slider .slider_card .slider_card_para {
    color: var(--white);
    font-weight: 200;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 2px;
}

.slider .slider_img {
    width: 350px;
    height: 673px;
    position: absolute;
    right: 25%;
    /* top: -34%; */
    transform: translateY(-67%);
}

.slider .slider_img img {
    width: 100%;
    height: 100%;
}

.slider .slider_btn_container {
    display: flex;
    justify-content: space-between;
    height: 47px;
    width: 120px;
    position: absolute;
    right: 18%;
    top: 12rem;
}

.slider .slider_btn_container button {
    background-color: var(--white);
    border: none;
    border-radius: 30px;
    padding: 10px 10px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    width: 48px;
    height: 48px;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .slider {
        display: flex;
        justify-content: space-between;
    }
    
    .slider .slider_card {
        background: none;
        border-radius: 20px;
        width: 48%;
        height: auto;
        padding: 2.5% 4%;
    }
    
    .slider .slider_card .slider_card_heading {
        margin-bottom: 16px;
        color: var(--yellow);
        font-weight: 300;
        font-size: 22px;
        line-height: 27px;
        letter-spacing: 0.3px;
    }
    
    .slider .slider_card .slider_card_para {
        color: var(--white);
        font-weight: 100;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
        letter-spacing: 0.5px;
    }
    
    .slider .slider_img {
        width: 250px;
        height: 485px;
        position: absolute;
        right: 27.5%;
        /* top: -34%; */
        transform: translateY(-60%);
    }
    
    .slider .slider_img img {
        width: 100%;
        height: 100%;
    }
    
    .slider .slider_btn_container {
        display: flex;
        justify-content: space-between;
        height: 47px;
        width: 100px;
        position: absolute;
        right: 18%;
        top: 25%
    }
    
    .slider .slider_btn_container button {
        background-color: var(--white);
        border: none;
        border-radius: 30px;
        padding: 10px 10px;
        /* display: flex;
        justify-content: center;
        align-items: center; */
        width: 40px;
        height: 40px;
    }

    .slider .slider_btn_container button img {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 600px) {
    .slider {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .slider .slider_card {
        background: none;
        border-radius: 20px;
        width: 100%;
        height: auto;
        padding: 6.5% 8%;
        margin-bottom: 30px;
    }
    
    .slider .slider_card .slider_card_heading {
        margin-bottom: 16px;
        color: var(--yellow);
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 1px;
    }
    
    .slider .slider_card .slider_card_para {
        color: var(--white);
        font-weight: 200;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0px;
        letter-spacing: 1.5px;
    }
    
    .slider .slider_img {
        width: 180px;
        height: auto;
        position: static;
        /* right: 27.5%; */
        /* top: -34%; */
        transform: translateY(-5%);
        transform: translateX(35%);
    }
    
    .slider .slider_img img {
        width: 100%;
        height: 100%;
    }
    
    .slider .slider_btn_container {
        display: flex;
        justify-content: space-between;
        height: 47px;
        width: 100px;
        position: static;
        margin-top: 15px;
        /* right: 18%;
        top: 25% */
        margin-left: 10px;
        transform: translateX(90%);
    }
    
    .slider .slider_btn_container button {
        background-color: var(--white);
        border: none;
        border-radius: 30px;
        padding: 10px 10px;
        /* display: flex;
        justify-content: center;
        align-items: center; */
        width: 40px;
        height: 40px;
    }

    .slider .slider_btn_container button img {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .slider {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .slider .slider_card {
        background: none;
        width: 100%;
        height: auto;
        padding: 5% 7%;
        margin-bottom: 30px;
    }
    
    .slider .slider_card .slider_card_heading {
        margin-bottom: 16px;
        color: #040726;
        font-weight: 500;
        font-size: 28px;
        line-height: 26px;
        letter-spacing: 1px;
    }
    
    .slider .slider_card .slider_card_para {
        color: #040726;
        font-weight: 200;
        font-size: 26px;
        line-height: 28px;
        margin-bottom: 0px;
        letter-spacing: 0.5px;
    }
    
    .slider .slider_img {
        width: 200px;
        height: 348px;
        position: static;
        /* right: 27.5%; */
        /* top: -34%; */
        transform: translateY(-5%);
    }
    
    .slider .slider_img img {
        width: 100%;
        height: 100%;
    }
    
    .slider .slider_btn_container {
        display: flex;
        justify-content: space-between;
        height: 47px;
        width: 140px;
        position: static;
        margin-top: 5px;
        /* right: 18%;
        top: 25% */
        margin-left: 10px;
    }
    
    .slider .slider_btn_container button {
        background-color: var(--white);
        border: none;
        padding: 10%;
        /* display: flex;
        justify-content: center;
        align-items: center; */
        width: 50px;
        height: 50px;
    }

    .slider .slider_btn_container button img {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}