#faq_container {
    min-height: 220vh;
}

.faq_cabtm_container {
    padding: 6.5% 0 10% 0;
    display: flex;
    justify-content: center;
}

@media (max-width: 600px) {
    .faq_cabtm_container {
        padding: 20% 0 20% 0;
        display: flex;
        justify-content: center;
    }

    .header_content {
        display: flex;
        justify-content: space-between;
        width: 73%;
        padding: 15% 0;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .faq_cabtm_container {
        padding: 20% 0 20% 0;
        display: flex;
        justify-content: center;
    }

    .header_content {
        display: flex;
        justify-content: space-between;
        width: 73%;
        /* padding: 15% 0; */
    }
}