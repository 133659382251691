.header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8rem;
}

.header_content {
    display: flex;
    justify-content: space-between;
    width: 62.5%;
    padding: 5% 0;
}

.header_content .header_small {
    color: var(--yellow);
    font-size: 18px;
    line-height: 68px;
    font-weight: 400;
    letter-spacing: 2px;
}

.header_content .header_small span {
    text-transform: capitalize;
}

.header_content .header_main {
    margin-top: -18px!important;
    color: var(--white);
    font-size: 50px;
    line-height: 50px;
    font-weight: 300;
    width: 80%;
    letter-spacing: 1px;
}

.header_content .blog_header_date {
    color: var(--white);
    font-weight: 300;
    text-transform: uppercase;
}

.header_content .header_img {
    transform: translateY(14px);
    width: 184.06px;
    height: 183.94px;
}


@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .header_content {
        display: flex;
        justify-content: space-between;
        width: 62.5%;
        padding: 5% 0;
    }
    
    .header_content .header_small {
        color: var(--yellow);
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 0.5px;
    }
    
    .header_content .header_main {
        margin-top: -30px!important;
        color: var(--white);
        font-size: 2.2rem;
        font-weight: 300;
        width: 80%;
        line-height: 38px;
    }
    
    .header_content .header_img {
        transform: translateY(28px);
        height: 120px;
        width: 120px;
    }
}

@media (max-width: 600px) {
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
    }
    
    .header_content {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: flex-start;
        width: 85%!important;
        padding: 5% 0;
    }
    
    .header_content .header_small {
        color: var(--yellow);
        font-size: 1.2rem;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 28px;
        margin-bottom: 5px;
    }
    
    .header_content .header_img {
        transform: translateY(0px)!important;
        width: 68.06px;
        height: 67.84px;
        margin-bottom: 20px;
    }
    
    .header_content .header_main {
        margin-top: 6px!important;
        color: var(--white);
        font-size: 1.7rem;
        font-weight: 300;
        width: 100%!important;
        line-height: 32px;
        letter-spacing: 1px;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .header_content {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: flex-start;
        width: 80%;
        padding: 10% 0;
    }
    
    .header_content .header_small {
        color: var(--yellow);
        font-size: 1.5rem;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 28px;
        margin-bottom: 5px;
    }
    
    .header_content .header_main {
        margin-top: 0px!important;
        color: var(--white);
        font-size: 2.2rem;
        font-weight: 400;
        width: 100%;
        line-height: 40px;
        letter-spacing: 1.5px;
    }
    
    .header_content .header_img {
        transform: translateY(0px)!important; 
        width: 131.06px;
        height: 120.84px;
        margin-bottom: 20px;
    }
    
    .header_content .mobile_main {
        margin-top: 15px!important;
        color: var(--white);
        font-size: 1.8rem;
        font-weight: 400;
        width: 100%;
        line-height: 34px;
        letter-spacing: 0px;
    }
}