.download_app {
    height: 887px;
    transform: translateY(-60px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
}

.download_app_content {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.download_app_content .download_app_photo img {
    width: 483px;
    height: 663px;
}

.download_app_content .download_app_text .download_app_text_heading {
    font-size: 2.5rem;
    color: var(--white);
    margin-bottom: 10px;
    letter-spacing: 2px;
    font-weight: 100;
}

.download_app_content .download_app_text .download_app_text_sub {
    color: var(--lightgrey);
    font-weight: 400;
    font-size: 1.5rem;
    font-weight: 100;
    letter-spacing: 1px;
}

.download_app_text .footer_downloads {
    display: flex;
    flex-direction: column;
    /* padding: 1% 0%; */
    margin-top: 28px;
}



.download_app_text .footer_downloads .footer_downloads_item {
   display: flex;
   align-items: flex-start;
   flex-direction: column;
   width: auto;
   margin-bottom: 34px;
   
}

.download_app_text .footer_downloads .footer_downloads_item .downloads_title {
   color: var(--yellow);
   font-size: 24px;
   line-height: 28px;
   margin-bottom: 12px;
   letter-spacing: 2px;
   font-weight: 100;
}

.download_app_text .footer_downloads .footer_downloads_item .downloads_links .google_play {
   margin-right: 4px;
   width: 206px;
   height: 60px;
}

.download_app_text .footer_downloads .footer_downloads_item .downloads_links .app_store {
   margin-right: 4px;
   width: 178px;
   height: 64px;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .download_app {
        height: 631px;
        transform: translateY(-60px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .download_app_content {
        width: 60%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .download_app_content .download_app_photo img {
        height: 30rem;
        width: 21rem;
    }
    
    .download_app_content .download_app_text .download_app_text_heading {
        font-size: 1.8rem;
        color: var(--white);
        margin-bottom: 10px;
        letter-spacing: 1px;
    }
    
    .download_app_content .download_app_text .download_app_text_sub {
        color: var(--lightgrey);
        font-weight: 400;
        font-size: 1.1rem;
        letter-spacing: 0.5px;
    }
    
    .download_app_text .footer_downloads {
        display: flex;
        flex-direction: column;
        /* padding: 1% 0%; */
        margin-top: 28px;
    }
    
    
    
    .download_app_text .footer_downloads .footer_downloads_item {
       display: flex;
       align-items: flex-start;
       flex-direction: column;
       width: auto;
       margin-bottom: 15px;
       
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_title {
       color: var(--yellow);
       font-size: 1.15rem;
       margin-bottom: 5px;
       letter-spacing: 0.5px;
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_links .google_play {
       margin-right: 4px;
       width: 105.17px;
       height: 30.79px;
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_links .app_store {
       margin-right: 4px;
       width: 110.17px;
       height: 40.79px;
    }
}

@media (max-width: 600px) {
    .download_app {
        height: auto;
        transform: translateY(-30px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20% 0;
    }
    
    .download_app_content {
        width: 85%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
    }
    
    .download_app_content .download_app_photo img {
        height: 18rem;
        width: 13.5rem;
        margin-bottom: 40px;
    }
    
    .download_app_content .download_app_text .download_app_text_heading {
        font-size: 1.4rem;
        color: var(--white);
        margin-bottom: 10px;
        letter-spacing: 1px;
    }
    
    .download_app_content .download_app_text .download_app_text_sub {
        color: var(--lightgrey);
        font-weight: 400;
        font-size: 1rem;
        letter-spacing: 1px;
    }
    
    .download_app_text .footer_downloads {
        display: flex;
        flex-direction: column;
        /* padding: 1% 0%; */
        margin-top: 28px;
    }
    
    
    
    .download_app_text .footer_downloads .footer_downloads_item {
       display: flex;
       align-items: flex-start;
       flex-direction: column;
       width: auto;
       margin-bottom: 20px;
       
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_title {
       color: var(--yellow);
       font-size: 1.15rem;
       margin-bottom: 5px;
       letter-spacing: .5px;
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_links .google_play {
       margin-right: 4px;
       width: 125.17px;
       height: 40.79px;
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_links .app_store {
       margin-right: 4px;
       width: 110.17px;
       height: 40.79px;
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .download_app {
        height: auto;
        transform: translateY(-30px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 18% 0;
    }
    
    .download_app_content {
        width: 80%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
    }
    
    .download_app_content .download_app_photo img {
        height: 27rem;
        width: 20.5rem;
        margin-bottom: 40px;
    }
    
    .download_app_content .download_app_text {
        width: 80%;
    }

    .download_app_content .download_app_text .download_app_text_heading {
        font-size: 2.2rem;
        color: var(--white);
        margin-bottom: 10px;
        letter-spacing: 2px;
    }
    
    .download_app_content .download_app_text .download_app_text_sub {
        color: var(--lightgrey);
        font-weight: 400;
        font-size: 1.5rem;
        letter-spacing: 1.5px;
    }
    
    .download_app_text .footer_downloads {
        display: flex;
        flex-direction: column;
        /* padding: 1% 0%; */
        margin-top: 28px;
    }
    
    
    
    .download_app_text .footer_downloads .footer_downloads_item {
       display: flex;
       align-items: flex-start;
       flex-direction: column;
       width: auto;
       margin-bottom: 20px;
       
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_title {
       color: var(--yellow);
       font-size: 1.2rem;
       margin-bottom: 10px;
       letter-spacing: 2px;
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_links .google_play {
       margin-right: 6px;
       width: 148.17px;
       height: 54.79px;
    }
    
    .download_app_text .footer_downloads .footer_downloads_item .downloads_links .app_store {
       margin-right: 4px;
       width: 145.17px;
       height: 54.79px;
    }
}