.iframe {
    pointer-events: none;
    width: 100%;
    position: absolute;
    top: 0;
    height: 80%;
    /* opacity: 0.1; */
}

header {
    height: 830px;
	width: 100%;
    /* background-image: url(''); */
	/* background-image: linear-gradient(#04072690, #04072690), url('./hero.jpg'); */
	/* background-image: linear-gradient(rgba(62, 126, 246,0.3),rgba(62, 126, 246,0.3)), url('./study.jpg'); */
	background-repeat: no-repeat;
	background-size: 100% 100%;
	/*clip-path: polygon(100% 0%, 100% 100%, 50% 100%, 0 100%, 0 0);*/
	position: relative;
	color: #fff;
    padding-bottom: 69.25%;
    top: -4.4rem;
    display: flex;
    justify-content: center;
}

header .videoheader_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .videoheader_text {
    transform: translate(12rem, 30rem);
    /* width: 100%; */
}

header .videoheader_text:last-child {
    transform: translate(-100%, 30rem);
}


.videoheader_text .h1 {
    width: 40%;
    font-size: 3.8rem;
    font-weight: 100!important;
}

.videoheader_text .h1 span {
    color: var(--yellow);
    font-weight: 100;
}

.videoheader_text .span {
    color: var(--lightgrey);
    font-weight: 100;
    width: 45%;
    font-size: 1.5rem;
    letter-spacing: 1px;
}

.videoheader_text button {
    padding: 1% 2%;
    margin-top: 1.5%;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    width: 16.5%;
    text-align: center;
    transition: background-color 0.5s ease-in-out;
}

.videoheader_text button .money_icon {
    width: 23.36px;
    height: 28px;
}

.videoheader_text button .arrow_right {
    width: 18px;
    height: 18px;
}

@media (max-width: 600px){
    .iframe {
        object-fit: cover;
    }

    header .videoheader_text {
        transform: translate(1.6rem, 26%);
        width: 85%;
        text-align: center;
    }
    
    header .videoheader_text:last-child {
        display: none;
    }
    .videoheader_text .h1 {
        width: 100%;
        font-size: 1.6rem;
        font-weight: 100!important;
        margin-bottom: 16px;
        letter-spacing: 1.5px;
        line-height: 2.4rem;
    }
    
    .videoheader_text .span {
        color: var(--lightgrey);
        font-weight: 100;
        width: 100%;
        font-size: 1rem;
        margin-bottom: 20px;
        letter-spacing: 1px;
    }
    
    .videoheader_text button {
        padding: 2.2% 3%;
        margin-top: 1.5%;
        font-size: 13px;
        width: 68%;
        letter-spacing: 1px;
        font-weight: bold;
    }

    header {
        height: 785px;
    }

    .videoheader_text button .money_icon {
        width: 16.36px;
        height: 21px;
    }
    
    .videoheader_text button .arrow_right {
        width: 10px;
        height: 10px;
        margin-left: 15px;
    }
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1)  {
    .iframe {
        pointer-events: none;
        width: 100%;
        top: 0;
        height: 80%;
        /* opacity: 0.1; */
    }
    
    header {
        height: 952px;
        width: 100%;
        /*background-image: linear-gradient(rgba(0,168,255,0.3), rgba(0,168,255,0.3)), url('./co.jpg');*/
        /* background-image: linear-gradient(rgba(62, 126, 246,0.3),rgba(62, 126, 246,0.3)), url('./study.jpg'); */
        background-repeat: no-repeat;
        background-size: 100% 70%;
        /*clip-path: polygon(100% 0%, 100% 100%, 50% 100%, 0 100%, 0 0);*/
        position: relative;
        color: #fff;
        /* padding-bottom: 70.25%; */
        top: -4.4rem;
        display: flex;
        justify-content: center;
    }

    
    header .videoheader_text {
        /* position: absolute;
        z-index: 1; */
        /* top: 16.7rem;  */
        transform: translate(8.5rem, 110%);
        /* left: 18.8%; */
    }

    header .videoheader_text:last-child {
        /* position: absolute;
        z-index: 1; */
        /* top: 16.7rem;  */
        transform: translate(-100%, 200%);
        /* left: 18.8%; */
    }

    header .videoheader_text:last-child div {
        height: 158px!important;
        width: 154px!important;
    }
    
    .videoheader_text .h1 {
        width: 40%;
        font-size: 2.69rem;
        font-weight: 100!important;
        letter-spacing: 0.5px;
    }
    
    .videoheader_text .h1 span {
        color: var(--yellow);
        letter-spacing: 0.5px;
    }
    
    .videoheader_text .span {
        color: var(--lightgrey);
        font-weight: 100;
        width: 45%;
        font-size: 1.05rem;
        letter-spacing: 0.5px;
    }
    
    .videoheader_text button {
        padding: 0.8% 2%;
        margin-top: 1.5%;
        font-size: 14px;
        width: 18%;
    }
    
    .videoheader_text button .money_icon {
        width: 23.36px;
        height: 28px;
    }
    
    .videoheader_text button .arrow_right {
        width: 14px;
        height: 14px;
    }
    
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1)  {
    .iframe {
        object-fit: cover;
    }

    header .videoheader_text {
        transform: translate(5.3rem, 12rem);
        width: 75%;
    }
    
    header .videoheader_text:last-child {
        display: none;
    }
    .videoheader_text .h1 {
        width: 100%;
        font-size: 1.5rem;
        font-weight: 100!important;
        margin-bottom: 16px;
        letter-spacing: 1px;
    }
    
    .videoheader_text .span {
        color: var(--lightgrey);
        font-weight: 100;
        width: 90%;
        font-size: 1.2rem;
        margin-bottom: 20px;
        line-height: 20px;
        letter-spacing: 0.5px;
    }
    
    .videoheader_text button {
        padding: 1.8% 5%;
        margin-top: 1.5%;
        font-size: 18px;
        width: 50%;
        letter-spacing: 1px;
        font-weight: bold;
    }

    header {
        height: 675px;
    }

    .videoheader_text button .money_icon {
        width: 16.36px;
        height: 21px;
    }
    
    .videoheader_text button .arrow_right {
        width: 10px;
        height: 10px;
        margin-left: 15px;
    }
}