/* box-shadow:
    inset 0 0 50px ,      
    inset 20px 0 80px #ffffff20,  
    inset -20px 0 80px #ffffff20,  
    inset 20px 0 300px #ffffff20, 
    inset -20px 0 300px #ffffff20,
    0 0 50px #ffffff30,     
    -10px 0 80px #ffffff30,        
    10px 0 80px #ffffff30;  */

.slide-right {
    animation: slide 1s linear infinite;
}

@keyframes glow {
    0% {
        opacity: 1;
    }
    60% {
        opacity: 0.5;
    }
    70% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes btn_water {
    0% {
        background: linear-gradient(to right, var(--white) 10%, var(--yellow) 0%) no-repeat
    }
    60% {
        background: linear-gradient(to right, var(--white) 30%, var(--yellow) 0%) no-repeat
    }
    70% {
        background: linear-gradient(to right, var(--white) 50%, var(--yellow) 0%) no-repeat
    }
    100% {
        background: linear-gradient(to right, var(--white) 100%, var(--yellow) 0%) no-repeat
    }
}

@keyframes slide {
    0% {
        transform: translateX(0%);
    }
    25% {
        transform: translateX(50%);
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    50% {
        transform: translateX(-100%);
        opacity: 0;
    }
    70%{
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 100%;
    }
}


@keyframes navToggle {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}