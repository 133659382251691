/* .footer {
    transform: translateY(0rem);
}

.App {
    height: 0vh;
} */

#cabtainservice {
    height: 490.5vh;
}

.cabtm_container {
    display: flex;
    justify-content: center;
    height: auto;
    align-items: center;
}

.faq_container {
    background-color: var(--darkblue);
    transform: translateY(-106px);
    padding-top: 90px;
    padding-bottom: 00px;
}

.faq_container .faq_container_title {
    text-align: center; 
    color: var(--white); 
    margin-bottom: 30px; 
    font-size: 40px; 
    line-height: 4; 
    letter-spacing: 0.5px;
}

.faq_container .footer {
    margin-top: 7%;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .faq_container {
        background-color: var(--darkblue);
        transform: translateY(-106px);
        padding-top: 30px;
        padding-bottom: 0px;
    }

    #cabtainservice .header_card_content {
        height: 220px;
    }

    #cabtainservice .header_card {
        height: 330px;
    }
}

@media (max-width: 600px) {
    .faq_container .faq_container_title {
        text-align: center; 
        color: var(--white); 
        margin-bottom: 32px; 
        font-size: 20px!important; 
        line-height: 1!important; 
        letter-spacing: 0.5px;
    }

    .faq_container {
        padding-bottom: 30px;
    }
}