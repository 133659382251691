.terms_link {
    padding: 5% 0;
    border-bottom: 0.75px solid rgba(73, 74, 126, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.terms_link p {
    color: var(--white);
    margin-bottom: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
}

.terms_link .active {
    color: var(--yellow);
}

.terms_link img {
    width: 18px;
    height: 18px;
}

.terms_content_info {
    font-size: 24px;
    line-height: 20px;
    color: var(--yellow);
    margin-bottom: 40px;
}

#terms_container .cabtm_privacy_content {
    flex-direction: row;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .terms_link {
        padding: 5% 0;
        border-bottom: 0.75px solid rgba(73, 74, 126, 0.5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    
    .terms_link p {
        color: var(--white);
        margin-bottom: 0;
        font-weight: 500;
        font-size: 17px;
        line-height: 18px;
    }
    
    .terms_link .active {
        color: var(--yellow);
    }
    
    .terms_link img {
        width: 16px;
        height: 16px;
    }
    
    .terms_content_info {
        font-size: 20px;
        line-height: 18px;
        color: var(--yellow);
        margin-bottom: 30px;
    }
}

@media (max-width: 600px) {
    .terms_link {
        padding: 5% 0;
        border-bottom: 0.75px solid rgba(73, 74, 126, 0.5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    
    .terms_link p {
        color: var(--white);
        margin-bottom: 0;
        font-weight: 500;
        font-size: 17px;
        line-height: 18px;
    }
    
    .terms_link .active {
        color: var(--yellow);
    }

    .cabtm_privacy_content_link {
        position: sticky;
    }
    
    .terms_link img {
        width: 16px;
        height: 16px;
    }
    
    .terms_content_info {
        font-size: 20px;
        line-height: 22px;
        color: var(--yellow);
        margin-bottom: 30px;
    }

    .cabtm_privacy_content_link {
        width: 100%!important;
    }

    .cabtm_privacy_content_content {
        width: 100%!important;
    }

    .sticky {
        position: fixed;
        top: 0;
        width: 100%;
    }

    #terms_container .cabtm_privacy_content {
        flex-direction: column;
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .terms_link {
        padding: 5% 0;
        border-bottom: 0.75px solid rgba(73, 74, 126, 0.5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
    
    .terms_link p {
        color: var(--white);
        margin-bottom: 0;
        font-weight: 500;
        font-size: 26px;
        line-height: 18px;
        letter-spacing: 2px;
    }
    
    .terms_link .active {
        color: var(--yellow);
    }

    .cabtm_privacy_content_link {
        position: sticky;
    }
    
    .terms_link img {
        width: 22px;
        height: 22px;
    }
    
    .terms_content_info {
        font-size: 28px;
        line-height: 28px;
        color: var(--yellow);
        margin-bottom: 30px;
        letter-spacing: 1px;
    }

    .cabtm_privacy_content_link {
        width: 100%!important;
    }

    .cabtm_privacy_content_content {
        width: 100%!important;
    }

    /* .sticky {
        position: fixed;
        top: 0;
        width: 70%!important;
      } */

    #terms_container .cabtm_privacy_content {
        flex-direction: column;
    }
}