/* .slider_section {
    
} */

.slider_section .slider_background {
    width: 100%;
}

.slider_section .slider_img_text {
    position: absolute;
    /* bottom: 74.5%; */
    left: 18%;
    transform: translateY(-20rem);
    color: var(--white);
    line-height: 45px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
}

.slider_section .slider_img_text p {
    font-size: 60px;
    line-height: 60px;
    width: 60%;
    margin-bottom: 10px;
    letter-spacing: 2px;
}

.slider_section .slider_img_text span {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 1px;
    color: #0D8944;
}

.slider_section .cabtm_slider_container {
    transform: translateY(-70px);
    height: auto;
}

.slider_section .cabtm_slider_content {
    width: 62.5%;
    padding: 6% 0;
    margin-bottom: 20px;
}

.slider_section .cabtm_slider_content .cabtm_slider_card_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 130px;
}

.slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card {
    background: none;
    border-radius: 20px;
    width: 48%;
    height: auto;
    padding: 3% 5.5%;
    /* border: 3px solid #494A7E; */
}

.slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_heading {
    color: #0D8944;
    font-weight: 100;
    font-size: 52px;
    line-height: 64px;
    margin-bottom: 16px;
    width: 120%;
}

.slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_para {
    font-size: 24px;
    line-height: 36px;
    color: #E2E2E2;
    width: 140%;
    letter-spacing: 1px;
    font-weight: 200;
    line-height: 36px;
}

.slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img {
    border-radius: 40px;
    width: 40%;
    height: auto;
}

#earnwithcabtm .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img {
    height: 540px;
}

.slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .slider_section .slider_background {
        width: 100%;
    }
    
    .slider_section .slider_img_text {
        position: absolute;
        /* bottom: 70.5%; */
        left: 20%;
        transform: translateY(-16rem);
        color: var(--white);
        line-height: 45px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
    }
    
    .slider_section .slider_img_text p {
        font-size: 42px;
        line-height: 42px;
        width: 60%;
        margin-bottom: 0px;
        letter-spacing: 1px;
    }
    
    .slider_section .slider_img_text span {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 1px;
        color: #0D8944;
    }
    
    .slider_section .cabtm_slider_content {
        width: 62.5%;
        padding: 6% 0;
        margin-bottom: 20px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card {
        background: none;
        border-radius: 20px;
        width: 48%;
        height: 280px;
        padding: 3% 5.5%;
        /* border: 3px solid #494A7E; */
    }

    #earnwithcabtm .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card {
        background: none;
        border-radius: 20px;
        width: 48%;
        height: 440px;
        padding: 3% 5.5%;
        /* border: 3px solid #494A7E; */
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_heading {
        color: #0D8944;
        font-weight: 400;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 14px;
        letter-spacing: 1px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_para {
        font-size: 18px;
        line-height: 26px;
        color: #E2E2E2;
        margin-bottom: 0!important;
        width: 120%;
        letter-spacing: 1px;
        font-weight: 200;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img {
        border-radius: 20px;
        width: 40%;
        height: auto;
    }

    #earnwithcabtm .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img {
        background: var(--white);
        box-shadow: 0px 2px 0px var(--lightblue);
        border-radius: 20px;
        width: 48%;
        height: 440px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 20px;
    }
}


@media (max-width: 600px) {
    .slider_section .slider_background {
        width: 100%;
        height: 14rem;
        object-fit: cover;
    }
    
    .slider_section .slider_img_text {
        position: absolute;
        /* bottom: 70.5%; */
        left: 10%;
        transform: translateY(-10rem);
        color: var(--white);
        line-height: 45px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
    }
    
    .slider_section .slider_img_text p {
        font-size: 30px;
        line-height: 30px;
        width: 70%;
        margin-bottom: 0px;
        letter-spacing: 1px;
    }
    
    .slider_section .slider_img_text span {
        font-size: 14px;
        line-height: 2px;
        letter-spacing: 1px;
    }

    .slider_section .cabtm_slider_container {
        transform: translateY(-18px);
        height: auto;
    }
    
    .slider_section .cabtm_slider_content {
        width: 85%;
        padding: 0 0;
        margin-bottom: 20px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card {
        background: none;
        border-radius: 20px;
        width: 94%;
        height: auto;
        padding: 0!important;
        margin-bottom: 40px;
        text-align: left;
        /* border: 3px solid #494A7E; */
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_heading {
        color: #0D8944;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 16px;
        width: 100%;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_para {
        font-size: 16px;
        line-height: 22px;
        color: var(--lightgrey);
        margin-bottom: 0!important;
        font-weight: 200;
        width: 100%;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img {
        border-radius: 20px;
        width: 90%;
        height: auto;
        margin-bottom: 40px;
        
    }

    #earnwithcabtm .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img {
        height: 180px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 20px;
    }
}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .slider_section .slider_background {
        width: 100%;
        height: 18rem;
        object-fit: cover;
    }
    
    .slider_section .slider_img_text {
        position: absolute;
        /* bottom: 70.5%; */
        left: 15%;
        transform: translateY(-12rem);
        color: var(--white);
        line-height: 45px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
    }
    
    .slider_section .slider_img_text p {
        font-size: 38px;
        line-height: 38px;
        width: 60%;
        margin-bottom: 10px;
        letter-spacing: 2px;
    }
    
    .slider_section .slider_img_text span {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 1px;
    }

    .slider_section .cabtm_slider_container {
        transform: translateY(-10px);
        height: auto;
    }
    
    .slider_section .cabtm_slider_content {
        width: 73%;
        padding: 15% 0;
        margin-bottom: 20px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 40px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card {
        background: var(--white);
        box-shadow: 0px 4px 0px var(--lightblue);
        width: 100%;
        height: auto;
        padding: 5.5% 8%;
        margin-bottom: 40px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_heading {
        color: var(--darkblue);
        font-weight: 500;
        font-size: 30px;
        line-height: 28px;
        margin-bottom: 16px;
        letter-spacing: 1px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card .cabtm_slider_card_para {
        font-size: 20px;
        line-height: 26px;
        color: var(--lightgrey);
        letter-spacing: 1px;
        font-weight: 200;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img {
        background: var(--white);
        box-shadow: 0px 4px 0px var(--lightblue);
        width: 100%;
        height: 310px;
        margin-bottom: 40px;
    }
    
    .slider_section .cabtm_slider_content .cabtm_slider_card_container .cabtm_slider_card_img img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}