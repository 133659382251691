.header_card_content {
    background: none;
    /* position: relative; */
    /* top: 30px; */
    height: 295px;
    border-radius: 30px;
    padding: 10%;
    padding-top: 6%;
}

#cabtainservice .header_card_content {
    height: 225px;
}

.header_card {
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    padding-top: 41px;
    /* height: 16rem; */
    /* background-color: black; */
    /* z-index: 1; */
    height: auto;
    width: 32%;
    border: 3px solid #494A7E;
    border-radius: 20px;
}

/* .header_card .header_card_icon img {
    width: 90px; 
    height: 90px;
} */

.bargained_icon {
    width: 90.7px; 
    height: 90.7px;
    /* margin-top: 20px; */
}

.secure_icon {
    width: 90.7px; 
    height: 90.7px;
    margin-top: 0px;
}

.header_card .header_card_icon {
    width: 141px;
    height: 141px;
    background-color: var(--white);
    box-shadow: 0px 2px 0px rgba(47, 49, 96, 0.2);
    transform: translateX(38px);
    /* position: absolute; */
    /* top: 100px; */
    z-index: 1;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.header_card_content .header_card_heading {
    color: var(--yellow);
    margin-bottom: 8px!important;
    font-weight: 100;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 1px;
}

.header_card_content .header_card_para {
    color: var(--white);
    font-weight: 100;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1px;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    /* .cabtm_container {
        height: 420px!important;
    } */

    .header_card_content {
        background: none;
        /* position: relative; */
        /* top: 30px; */
        height: 275px;
        border-radius: 30px;
        padding: 10%;
        
    }
    
    .header_card {
        padding-top: 1.8%;
        /* height: 16rem; */
        /* background-color: black; */
        /* z-index: 1; */
        height: auto;
        width: 32%;
        border: 3px solid #494A7E;
        border-radius: 20px;
    }
    
    .header_card .header_card_icon img {
        height: 68px;
        width: 68px;
    }

    .bargained_icon {
        height: 76px!important;
        width: 76px!important;
        /* margin-top: 30px; */
    }

    .money_icon {
        height: 80px!important;
        width: 80px!important;
    }
    
    .secure_icon {
        height: 58px!important;
        width: 58px!important;
        margin-top: 0px;
    }
    
    .header_card .header_card_icon {
        width: 110px;
        height: 110px;
        background-color: var(--white);
        box-shadow: 0px 2px 0px rgba(47, 49, 96, 0.2);
        transform: translateX(24px);
        /* position: absolute; */
        /* top: 105px; */
        z-index: 1;
        border-radius: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4%;
    }
    
    
    .header_card_content .header_card_heading {
        color: var(--yellow);
        margin-bottom: 10px!important;
        font-weight: 100;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0px;
    }
    
    .header_card_content .header_card_para {
        color: var(--white);
        font-weight: 100; 
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
}

@media (max-width: 600px) {
    /* .cabtm_container {
        height: 420px!important;
    } */

    .header_card_content {
        background: none;
        /* position: relative; */
        /* top: 30px; */
        height: 320px;
        border-radius: 20px;
        padding: 5% 10% 5% 10%;
        
    }
    
    .header_card {
        padding-top: 1.8%;
        /* position: relative; */
        /* bottom: 3.5rem; */
        /* height: 16rem; */
        /* background-color: black; */
        /* z-index: 1; */
        height: auto;
        width: 100%;
        margin-bottom: 20px;
    }

    .header_card:last-child {
        margin-bottom: 0px;
    }
    
    .header_card .header_card_icon img {
        height: 70px;
        width: 70px;
    }

    .bargained_icon {
        height: 58px!important;
        width: 58px!important;
        /* margin-top: 20px; */
    }
    
    .secure_icon {
        height: 56px!important;
        width: 56px!important;
        margin-top: 0px;
    }

    #cabtainservice .header_card_content {
        height: 240px;
    }
    
    .header_card .header_card_icon {
        width: 100px;
        height: 100px;
        background-color: var(--white);
        box-shadow: 0px 2px 0px var(--lightblue);
        transform: translateX(24px);
        /* position: relative; */
        /* top: 80px; */
        z-index: 1;
        border-radius: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8%;
    }
    
    
    .header_card_content .header_card_heading {
        color: var(--yellow);
        margin-bottom: 10px!important;
        font-weight: 100;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 1px;
    }
    
    .header_card_content .header_card_para {
        color: var(--white);
        font-weight: 100; 
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.8px;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    /* .cabtm_container {
        height: 420px!important;
    } */

    .header_card_content {
        background: none;
        position: relative;
        top: 30px;
        height: auto;
        padding: 15% 8% 2% 8%;
        
    }
    
    .header_card {
        padding-top: 1.8%;
        position: relative;
        bottom: 3.5rem;
        /* height: 16rem; */
        /* background-color: black; */
        /* z-index: 1; */
        height: auto;
        width: 75%;
    }
    
    .header_card .header_card_icon img {
        height: 60px!important;
        width: 60px!important;
    }

    .bargained_icon {
        height: 62px!important;
        width: 62px!important;
        margin-top: 20px;
    }
    
    .secure_icon {
        height: 60px!important;
        width: 60px!important;
        margin-top: 0px;
    }
    
    .header_card .header_card_icon {
        width: 120px;
        height: 120px;
        background-color: var(--white);
        box-shadow: 0px 4px 0px var(--lightblue);
        transform: translateX(24px);
        position: relative;
        top: 80px;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    
    .header_card_content .header_card_heading {
        color: var(--yellow);
        margin-bottom: 10px!important;
        font-weight: 400;
        font-size: 24px;
        line-height: 26px;
        letter-spacing: 1px;
    }
    
    .header_card_content .header_card_para {
        color: var(--white);
        font-weight: 400; 
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.7px;
    }
}