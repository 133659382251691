.footer {
    background-color: #040726;
    /* background-image: url('./footer_bg.svg'); */
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    transform: translateY(-2px);
}

.footer .bg_img {
    display: none;
}

.footer .footer_body {
    margin-top: 4%;
    width: 63%;
}

.footer a {
    color: var(--yellow);
}
.footer .footer_body .logo_slogn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-bottom: 4%; */
    /* border-bottom: 2px solid rgba(73, 74, 126, 0.5); */
}

.footer .footer_body .logo_slogn .footer_logo {
    width: 161.22px;
    height: 160.56px;
    border-radius: 200px;
    /* animation: glow 5s linear infinite; */
}

.footer .footer_body .logo_slogn .footer_logo div {
    margin: 0px 0px!important;
}

.footer .footer_body .logo_slogn .slogan {
    width: 550px;
    height: 57.7px;
    margin-bottom: 30px;
    margin-left: auto;
}

.footer hr {
    background-color: var(--lightblue);
    height: 2px;
    width: 100%;
}

.footer .footer_links_container {
   width: 63%;
}

.footer .footer_links_container .footer_links {
    display: flex;
    justify-content: space-between;
    padding: 2.2% 0%;
    
}

.footer .footer_links_container .footer_links .footer_link_item {
    width: 200px;
}


.footer .footer_links_container .footer_links .footer_link_item .link_title {
    font-weight: 100;
    font-size: 24px;
    line-height: 28px;
    color: var(--white);
    letter-spacing: 2px;
}

.footer .footer_links_container .footer_links .footer_link_item .link_links {
    display: flex;
    flex-direction: column;
}

.footer .footer_links_container .footer_links .footer_link_item .link_link {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: var(--lightgrey);
    text-decoration: none;
    margin-bottom: 16px;
    letter-spacing: 1px;
}

.footer .footer_links_container .footer_links .footer_link_item .link_link:hover {
    color: #C29200!important;
}

.footer .footer_socials_container .footer_socials .footer_socials_item_link .social_link:hover {
    color: #C29200!important;
}

.footer .footer_socials_container {
    width: 63%;
 }
 
 .footer .footer_socials_container .footer_socials {
     display: flex;
     justify-content: space-between;
     padding: 1% 0%;
     
 }
 
 .footer .footer_socials_container .footer_socials .footer_socials_item_icon {
     display: flex;
     justify-content: space-between;
     align-items: center;
     width: 22%;
 }

 .footer .footer_socials_container .footer_socials .footer_socials_item_icon a {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer .footer_socials_container .footer_socials .footer_socials_item_icon a:hover {
    opacity: 0.9;
}

.footer .copyright a:hover {
    opacity: 0.9;
}

 .footer .footer_socials_container .footer_socials .footer_socials_item_icon .facebook_icon {
    height: 30px;
    width: 18px;
}

.footer .footer_socials_container .footer_socials .footer_socials_item_icon .youtube_icon {
    height: 42px;
    width: 29.44px;
    
}

.footer .footer_socials_container .footer_socials .footer_socials_item_icon .youtube_icon[src="/assets/youtube.svg"] {
    transform: translateY(2px);
}


 .footer .footer_socials_container .footer_socials .footer_socials_item_link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 36%;
}

.footer .footer_socials_container .footer_socials .footer_socials_item_link .social_link {
    color: var(--lightgrey);
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 2px;
}

/* .footer .footer_socials_container .footer_socials .footer_socials_item_icon img {
    
    width: 42px;
    height: 29.44px;
} */


.footer .footer_downloads_container {
    width: 63%;
    padding-bottom: 1%;
 }
 
 .footer .footer_downloads_container .footer_downloads {
     display: flex;
     justify-content: flex-start;
     padding: 1% 0%;
     
 }
 
 .footer .footer_downloads_container .footer_downloads .footer_downloads_item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: auto;
    margin-right: 37px;
    margin-left: 2px;
}

.footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_title {
    color: var(--yellow);
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 2px;
}

.footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .google_play {
    margin-right: 4px;
    width: 124.17px;
    height: 35.79px;
}

.footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .app_store {
    margin-right: 4px;
    width: 102.33px;
    height: 35.79px;
}

.footer .copyright_container {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
}

.footer .copyright_container .copyright {
    color: var(--lightgrey);
    font-size: 18px;
    line-height: 21px;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .footer {
        background-color: #040726;
        /* background-image: url('./footer_bg.svg'); */
        /* height: 1454px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    
    /* .footer .bg_img {
        width: 100%;
        position: relative;
    } */
    
    .footer .footer_body {
        margin-top: 4%;
        width: 63%;
    }
    
    .footer .footer_body .logo_slogn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* padding-bottom: 0.5%; */
        /* border-bottom: 2px solid rgba(73, 74, 126, 0.5); */
    }
    
    .footer .footer_body .logo_slogn .footer_logo {
        height: 108px;
        width: 114px;
    }
    
    .footer .footer_body .logo_slogn .slogan {
        height: 140px;
        width: 57%;
    }
    
    .footer hr {
        background-color: var(--lightblue);
        height: 2px;
        width: 100%;
    }
    
    .footer .footer_links_container {
       width: 63%;
    }
    
    .footer .footer_links_container .footer_links {
        display: flex;
        justify-content: space-between;
        padding: 2.2% 0%;
        
    }
    
    .footer .footer_links_container .footer_links .footer_link_item {
        width: 170px;
    }
    
    
    .footer .footer_links_container .footer_links .footer_link_item .link_title {
        font-weight: 400;
        color: var(--white);
        letter-spacing: 1px;
        font-size: 1.2rem;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item .link_links {
        display: flex;
        flex-direction: column;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item .link_link {
        font-weight: 500;
        color: var(--lightgrey);
        text-decoration: none;
        letter-spacing: 1px;
        font-size: 1rem;
        margin-bottom: 8px;
    }
    
    .footer .footer_socials_container {
        width: 63%;
     }
     
     .footer .footer_socials_container .footer_socials {
         display: flex;
         justify-content: space-between;
         padding: 1% 0%;
         
     }
     
     .footer .footer_socials_container .footer_socials .footer_socials_item_icon {
         display: flex;
         justify-content: space-between;
         align-items: center;
         width: 30%;
     }
    
     .footer .footer_socials_container .footer_socials .footer_socials_item_link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 38.5%;
    }
    
    .footer .footer_socials_container .footer_socials .footer_socials_item_link .social_link {
        color: var(--lightgrey);
        font-size: 0.9rem;
        letter-spacing: 1px;
        font-weight: 500;
    }
    
    .footer .footer_socials_container .footer_socials .footer_socials_item_icon .facebook_icon {
        height: 28px;
        width: 16px;
    }
    
    .footer .footer_socials_container .footer_socials .footer_socials_item_icon .youtube_icon {
        height: 40px;
        width: 27.44px;
    }
    
    
    .footer .footer_downloads_container {
        width: 63%;
        padding-bottom: 1%;
     }
     
     .footer .footer_downloads_container .footer_downloads {
         display: flex;
         justify-content: flex-start;
         padding: 1% 0%;
         
     }
     
     .footer .footer_downloads_container .footer_downloads .footer_downloads_item {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: auto;
        margin-right: 37px;
        margin-left: 2px;
        letter-spacing: 0px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_title {
        color: var(--yellow);
        font-size: 0.8rem;
        margin-bottom: 1px;
        letter-spacing: 0.5px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .google_play {
        margin-right: 4px;
        width: 84.17px;
        height: 25.79px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .app_store {
        margin-right: 4px;
        width: 74.17px;
        height: 25.79px;
    }
    
    .footer .copyright_container {
        margin-top: 14px;
    }
    
    .footer .copyright_container .copyright {
        color: var(--lightgrey);
        font-size: 0.8rem;
        letter-spacing: 0.5px;
    }
}

@media (max-width: 600px) {
    .footer {
        background-color: #040726;
        background-image: none;
        /* height: 1454px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        background-size: cover;
        background-position: 100% 0;
        background-repeat: no-repeat;
        position:relative;
        z-index:1;
        /* width:200px;
        height:200px; */
    }

    .footer:before{
        position:absolute;
        z-index:-1;
        bottom: 0;
        left:0;
        width:100%;
        height:80%;
        content:"";
        background-color: #040726;
    }
    
    .footer .bg_img {
        display: block;
        position: absolute;
        z-index: -1;
        width: 100vw;
        /* height: 110vh; */
    }

    .footer .footer_body .logo_slogn {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 140px;
        /* padding-bottom: 0.5%; */
        /* border-bottom: 2px solid rgba(73, 74, 126, 0.5); */
    }
    
    .footer .footer_body {
        margin-top: 12%;
        width: 80%;
    }

    .footer .footer_body .logo_slogn .footer_logo {
        height: 108px;
        width: 114px;
    }

    .footer .footer_body .logo_slogn .footer_logo div {
        height: 88px!important;
        width: 84px!important;
    }

    .footer .footer_body .logo_slogn .slogan {
        height: 100px;
        width: 100%;
        margin-bottom: 0px;
    }
    
    .footer hr {
        background-color: var(--lightblue);
        height: 2px;
        width: 100%;
    }
    
    .footer .footer_links_container {
       width: 80%;
    }
    
    .footer .footer_links_container .footer_links {
        display: flex;
        justify-content: space-between;
        padding: 2.2% 0%;
        flex-wrap: wrap;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item {
        width: 142px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    
    
    .footer .footer_links_container .footer_links .footer_link_item .link_title {
        font-weight: 400;
        color: var(--white);
        letter-spacing: 1px;
        font-size: 1rem;
        line-height: 16px;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item .link_links {
        display: flex;
        flex-direction: column;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item .link_link {
        font-weight: 400;
        color: var(--lightgrey);
        text-decoration: none;
        letter-spacing: 0.8px;
        font-size: 0.8rem;
        margin-bottom: 8px;
    }
    
    .footer .footer_socials_container {
        width: 80%;
     }
     
     .footer .footer_socials_container .footer_socials {
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         padding: 1% 0%;

     }
     
     .footer .footer_socials_container .footer_socials .footer_socials_item_icon {
         display: flex;
         justify-content: space-between;
         align-items: center;
         width: 80%;
         margin-bottom: 20px;

     }
    
     .footer .footer_socials_container .footer_socials .footer_socials_item_link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    
    .footer .footer_socials_container .footer_socials .footer_socials_item_link .social_link {
        color: var(--lightgrey);
        font-size: 0.8rem;
        letter-spacing: 0.5px;
        margin-right: 0px;
    }

    .footer .footer_socials_container .footer_socials .footer_socials_item_icon .facebook_icon {
        height: 26px;
        width: 14px;
    }
    
    .footer .footer_socials_container .footer_socials .footer_socials_item_icon .youtube_icon {
        height: 21px;
        width: 28.44px;
    }
    
    
    .footer .footer_downloads_container {
        width: 80%;
        padding-bottom: 1%;
     }
     
     .footer .footer_downloads_container .footer_downloads {
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         padding: 1% 0%;

     }
     
     .footer .footer_downloads_container .footer_downloads .footer_downloads_item {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        margin-right: 30px;
        margin-left: 2px;
        letter-spacing: 0px;
        margin-bottom: 10px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_title {
        color: var(--yellow);
        font-size: 0.7rem;
        margin-bottom: 3px;
        letter-spacing: 1px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .google_play {
        margin-right: 6px;
        width: 84.17px;
        height: 26.79px;
        margin-bottom: 5px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .app_store {
        margin-right: 4px;
        width: 74.17px;
        height: 26.79px;
    }
    
    .footer .copyright_container {
        margin-top: 14px;
        flex-direction: column;
    }
    
    .footer .copyright_container .copyright {
        color: var(--lightgrey);
        font-size: 0.8rem;
        letter-spacing: 0.5px;
    }

}


@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .footer {
        background-color: var(--darkblue);
        background-image: none;
        /* height: 1454px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        background-size: cover;
        background-position: 100% 0;
        background-repeat: no-repeat;
        position:relative;
        z-index:1;
        /* width:200px;
        height:200px; */
    }

    .footer:before{
        position:absolute;
        z-index:-1;
        bottom: 0;
        left:0;
        width:100%;
        height:80%;
        content:"";
        background-color: #040726;
    }
    
    .footer .bg_img {
        display: block;
        position: absolute;
        z-index: -1;
        width: 100vw;
        /* height: 110vh; */
    }

    .footer .footer_body .logo_slogn {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        /* padding-bottom: 0.5%; */
        /* border-bottom: 2px solid rgba(73, 74, 126, 0.5); */
    }
    
    .footer .footer_body {
        margin-top: 38%;
        width: 80%;
    }
    
    .footer .footer_body .logo_slogn .footer_logo {
        height: 108px;
        width: 114px;
    }

    .footer .footer_body .logo_slogn .slogan {
        height: 100px;
        width: 100%;
        margin-bottom: 0px;
    }
    
    .footer hr {
        background-color: var(--lightblue);
        height: 2px;
        width: 100%;
    }
    
    .footer .footer_links_container {
       width: 80%;
    }
    
    .footer .footer_links_container .footer_links {
        display: flex;
        justify-content: space-between;
        padding: 2.2% 0%;
        flex-wrap: wrap;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item {
        width: 40%;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    
    
    .footer .footer_links_container .footer_links .footer_link_item .link_title {
        font-weight: 400;
        color: var(--white);
        letter-spacing: 1.5px;
        font-size: 1.6rem;
        line-height: 20px;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item .link_links {
        display: flex;
        flex-direction: column;
    }
    
    .footer .footer_links_container .footer_links .footer_link_item .link_link {
        font-weight: 400;
        color: var(--lightgrey);
        text-decoration: none;
        letter-spacing: 0.8px;
        font-size: 1.3rem;
        margin-bottom: 15px;
    }
    
    .footer .footer_socials_container {
        width: 80%;
     }
     
     .footer .footer_socials_container .footer_socials {
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         padding: 1% 0%;

     }
     
     .footer .footer_socials_container .footer_socials .footer_socials_item_icon {
         display: flex;
         justify-content: space-between;
         align-items: center;
         width: 22.5%;
         margin-bottom: 35px;

     }
    
     .footer .footer_socials_container .footer_socials .footer_socials_item_link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    
    .footer .footer_socials_container .footer_socials .footer_socials_item_link .social_link {
        color: var(--lightgrey);
        font-size: 1.3rem;
        letter-spacing: 1px;
        margin-right: 20px;
    }

    .footer .footer_socials_container .footer_socials .footer_socials_item_icon .facebook_icon {
        height: 34px;
        width: 22px;
    }
    
    .footer .footer_socials_container .footer_socials .footer_socials_item_icon .youtube_icon {
        height: 28px;
        width: 36.44px;
    }
    
    
    .footer .footer_downloads_container {
        width: 80%;
        padding-bottom: 1%;
     }
     
     .footer .footer_downloads_container .footer_downloads {
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         padding: 1% 0%;

     }
     
     .footer .footer_downloads_container .footer_downloads .footer_downloads_item {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        margin-right: 30px;
        margin-left: 2px;
        letter-spacing: 0px;
        margin-bottom: 20px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_title {
        color: var(--yellow);
        font-size: 1.2rem;
        margin-bottom: 10px;
        letter-spacing: 2px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .google_play {
        margin-right: 10px;
        width: 128.17px;
        height: 45.79px;
        margin-bottom: 5px;
    }
    
    .footer .footer_downloads_container .footer_downloads .footer_downloads_item .downloads_links .app_store {
        margin-right: 4px;
        width: 128.17px;
        height: 45.79px;
    }
    
    .footer .copyright_container {
        margin-top: 14px;
    }
    
    .footer .copyright_container .copyright {
        color: var(--lightgrey);
        font-size: 1.2rem;
        letter-spacing: 0.5px;
    }
}