.thought_container {
    padding: 5.6% 0 0% 0;
    transform: translateY(-3.6rem);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.thought_container .thought_content {
    width: 63.5%;
}

.thought_text {
    color: var(--white);
    font-size: 40px;
    line-height: 50px;
    margin-left: 5px;
    letter-spacing: 2px;
    margin-bottom: 34px;
    font-weight: 100;
}

a {
    text-decoration: none;
}

.thought_container .thought_card_container {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-between;
    /* align-items: center; */
    padding: 1.5% 0;
}

.thought_container .thought_card_container .thought_card {
    background-color: var(--white);
    border-radius: 20px 20px 20px 20px;
    box-shadow: 0px 2px 0px var(--lightblue);
    width: 578px;
    min-height: 100%;
    margin-bottom: 30px;
}

.thought_container .thought_card_container .thought_card .thought_card_img img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

.thought_container .thought_card_container .thought_card .thought_card_img {
    height: 318px;
}

.thought_container .thought_card_container .thought_card .thought_card_info {
    padding: 4.4% 7%;
}

.thought_container .thought_card_container .thought_card .thought_card_info p {
    margin-bottom: 0;
}


.thought_container .thought_card_container .thought_card .thought_card_info .thought_card_text {
    color: #069F03;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 1px;
}

.thought_container .media_content {
    width: 63.5%;
    margin-top: 4rem;
}


.thought_container .media_card_container {
    margin-top: 2.38rem;
}

.thought_container .media_card {
    background: var(--white);
    box-shadow: 0px 2px 0px var(--lightblue);
    border-radius: 20px;
    padding: 3% 8.2% 1.5% 8.2%;
    height: auto;
} 

.thought_container .media_card_container .media_card .media_card_img {
    width: 265px;
    height: 80px;
}

.thought_container .media_card_container .media_card .media_card_img img {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.thought_container .media_card_container .media_card .media_card_text {
    align-items: center;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.thought_container .media_card_container .media_card .media_card_text .media_card_heading {
    color: var(--darkblue);
    font-weight: 500;
    font-size: 44px;
    line-height: 40px;
    width: 50%;
}

.thought_container .media_card_container .media_card .media_card_text .media_card_para {
    color: var(--lightgrey);
    font-weight: 200;
    font-size: 28px;
    line-height: 40px;
    font-style: italic;
    width: 50%;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1)  {
    .thought_container {
        padding: 5.6% 0 0% 0;
        transform: translateY(-3.6rem);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .thought_container .thought_content {
        width: 63.5%;
    }
    
    .thought_text {
        color: var(--white);
        font-size: 1.77rem;
        line-height: 37px;
        margin-left: 5px;
        letter-spacing: 1px;
    }
    
    .thought_container .thought_card_container {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        /* align-items: center; */
        padding: 1.5% 0;
    }
    
    .thought_container .thought_card_container .thought_card {
        background-color: var(--white);
        border-radius: 10px 10px 10px 10px;
        box-shadow: 0px 2px 0px var(--lightblue);
        width: 48.5%;
        min-height: 100%;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_img img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 10px 10px 0px 0px;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_img {
        height: 14.2rem;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_info {
        padding: 4.4% 7%;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_info .thought_card_text {
        color: #069F03;
        font-size: 1.4rem;
        line-height: 26px;
        letter-spacing: 0.8px;
    }
    
    .thought_container .media_content {
        width: 63.5%;
        margin-top: 4rem;
    }
    
    
    .thought_container .media_card_container {
        margin-top: 2.38rem;
    }
    
    .thought_container .media_card {
        background: var(--white);
        box-shadow: 0px 2px 0px var(--lightblue);
        border-radius: 20px;
        padding: 3% 8.2% 1.5% 8.2%;
        height: auto;
    } 
    
    .thought_container .media_card_container .media_card .media_card_img {
        height: 65px;
        width: 28%;
    }
    
    .thought_container .media_card_container .media_card .media_card_img img {
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
    
    .thought_container .media_card_container .media_card .media_card_text {
        align-items: center;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }
    
    .thought_container .media_card_container .media_card .media_card_text .media_card_heading {
        color: var(--darkblue);
        font-weight: 500;
        font-size: 1.62rem;
        letter-spacing: 0px;
        margin-bottom: 0;
        line-height: 28px;
    }
    
    .thought_container .media_card_container .media_card .media_card_text .media_card_para {
        color: var(--lightgrey);
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 28px;
        letter-spacing: 0px;
    }
}

@media (max-width: 600px) {
    .thought_container {
        padding-top: 16%;
        transform: translateY(-1.6rem);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .thought_container .thought_content {
        width: 85%;
    }
    
    .thought_text {
        font-size: 1.5rem;
        color: var(--white);
        line-height: 30px;
        margin-left: 5px;
        letter-spacing: 1px;
        width:100%;
    }
    
    .thought_container .thought_card_container {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        /* align-items: center; */
        /* padding: 1.5% 0; */
    }
    
    .thought_container .thought_card_container .thought_card {
        background-color: var(--white);
        border-radius: 15px;
        box-shadow: 0px 2px 0px var(--lightblue);
        width: 100%;
        height: auto;
        margin-bottom: 30px;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_img img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 15px 15px 0px 0px;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_img {
        height: 10.2rem;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_info {
        padding: 2.4% 7%;
        padding-top: 5.5%;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_info .thought_card_text {
        color: #069F03;
        font-size: 1rem;
        line-height: 20px;
        letter-spacing: 0.5px;
    }
    
    .thought_container .media_content {
        width: 85%;
        margin-top: 2rem;
    }
    
    
    .thought_container .media_card_container {
        margin-top: 2.2rem;
    }
    
    .thought_container .media_card {
        background: var(--white);
        box-shadow: 0px 2px 0px var(--lightblue);
        border-radius: 15px;
        padding: 8.2%;
        height: auto;
    } 
    
    .thought_container .media_card_container .media_card .media_card_img {
        height: 70px;
        width: 60%;
    }
    
    .thought_container .media_card_container .media_card .media_card_img img {
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
    
    .thought_container .media_card_container .media_card .media_card_text {
        margin-top: 20px;
        flex-direction: column;

    }
    
    .thought_container .media_card_container .media_card .media_card_text .media_card_heading {
        color: var(--darkblue);
        font-weight: 500;
        font-size: 1.35rem;
        line-height: 26px;
        letter-spacing: 0px;
        width: 100%;
    }
    
    .thought_container .media_card_container .media_card .media_card_text .media_card_para {
        color: var(--lightgrey);
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 24px;
        letter-spacing: 0px;
        width: 100%;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .thought_container {
        padding: 16% 0;
        transform: translateY(-1.6rem);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .thought_container .thought_content {
        width: 80%;
    }
    
    .thought_text {
        font-size: 2.2rem;
        color: var(--white);
        line-height: 42px;
        margin-left: 5px;
        letter-spacing: 2px;
        width:100%;
    }
    
    .thought_container .thought_card_container {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        /* align-items: center; */
        /* padding: 1.5% 0; */
    }
    
    .thought_container .thought_card_container .thought_card {
        background-color: var(--white);
        box-shadow: 0px 2px 0px var(--lightblue);
        width: 100%;
        height: auto;
        margin-bottom: 40px;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_img img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_img {
        height: 20.2rem;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_info {
        padding: 4.4% 7%;
    }
    
    .thought_container .thought_card_container .thought_card .thought_card_info .thought_card_text {
        color: #069F03;
        font-size: 2rem;
        line-height: 34px;
        letter-spacing: 1px;
    }
    
    .thought_container .media_content {
        width: 77%;
        margin-top: 2.5rem;
    }
    
    
    .thought_container .media_card_container {
        margin-top: 2.2rem;
    }
    
    .thought_container .media_card {
        background: var(--white);
        box-shadow: 0px 2px 0px var(--lightblue);
        padding: 8.2%;
        height: auto;
    } 
    
    .thought_container .media_card_container .media_card .media_card_img {
        height: 80px;
        width: 50%;
    }
    
    .thought_container .media_card_container .media_card .media_card_img img {
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
    
    .thought_container .media_card_container .media_card .media_card_text {
        margin-top: 20px;
    }
    
    .thought_container .media_card_container .media_card .media_card_text .media_card_heading {
        color: var(--darkblue);
        font-weight: bold;
        font-size: 2rem;
        line-height: 36px;
        letter-spacing: 0px;
    }
    
    .thought_container .media_card_container .media_card .media_card_text .media_card_para {
        color: var(--lightgrey);
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 28px;
        letter-spacing: 0px;
    }
  }